import React, { useEffect, useState } from "react";
import AtsCard from "../../../../../../components/AtsCard";
import { Box } from "@mui/material";
import AtsDataTable from "../../../../../../components/AtsDataTable";
import {
  AccountCircle,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import { FilterIcon } from "../../../../../../assets/icons/Icons";
import AtsCustomButton from "../../../../../../components/AtsCustomButton";
import { Stack } from "@mui/material";
import { IColumnProps } from "../../../../../../types/commonType";
import { BORDERS, COLORS } from "../../../../../../theme";
import AtsIcon from "../../../../../../components/AtsIcon";
import AtsTitleText from "../../../../../../components/AtsTitleText";
import { ProjectContractorsStatusEnum } from "../../../../../../enums/projectEnums";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/store";
import { getContractorDetailsState } from "../../../../../../redux/slices/contractorSlice";
import { ProjectService } from "../../../../../../Api/projectServices";
import { useParams } from "react-router-dom";
import { CandidateListResponse } from "../../../../../../types/projectTypes";
import {
  getProjectDetailsState,
  setCurrentIndex,
  setSelectedProjectDetailsTab,
} from "../../../../../../redux/slices/projectDetailsSlice";
import AtsRoundedButton from "../../../../../../components/AtsRoundedButton";

interface contractorProps {
  uid: string;
  first_name: string;
  last_name: string;
  role: string;
  teamName: string;
  email: string;
}

const invoicesData: contractorProps[] = [
  {
    uid: "1",
    first_name: "Alex",
    last_name: "Novak",
    role: "Sr. Java",
    teamName: "Java Team",
    email: "daniel.anderson@nvidia.com",
  },
  {
    uid: "2",
    first_name: "Aayush",
    last_name: "Mittal",
    role: "Sr. Java",
    teamName: "Java Team",
    email: "daniel.anderson@nvidia.com",
  },
];
const LEAD_COLUMN: IColumnProps[] = [
  {
    key: "candidate_id",
    header: "Team Member",
    width: "auto",
    minWidth: "200px",
  },

  {
    key: "email",
    header: "Email",
    width: "auto",
    minWidth: "200px",
  },

  {
    key: "action",
    header: "",
    width: "60px",
    minWidth: "60px",
  },
];

const cellStyles = {
  padding: "5px 10px",
};

const TeamMember = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const {
    projectDocuments,
    selectedProjectDetailsTab,
    projectDocsList,
    currentIndex,
    projectTab,
  } = useAppSelector(getProjectDetailsState);
  const { selectedContractor } = useAppSelector(getContractorDetailsState);
  const [hoveredRow, setHoveredRow] = useState<string | null>(null);
  const [contractors, setContractors] = useState<CandidateListResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  // useEffect(() => {
  //   Object.keys(selectedContractor).length && getContractorDetails();
  // }, [selectedContractor]);

  const customizeRow = (
    column: IColumnProps,
    contractor: CandidateListResponse,
    index: number
  ) => {
    switch (column.key) {
      case "candidate_id":
        return (
          <td style={{ ...cellStyles }} key={column.key}>
            <Stack gap={1}>
              <div
                style={{
                  cursor: "pointer",
                  display: "inline-block",
                  padding: 10,
                }}
              >
                <Stack direction={"row"} alignItems={"center"} gap={0.3}>
                  <Stack>
                    <AccountCircle sx={{ fontSize: 70, color: "#6d6d6d" }} />
                  </Stack>
                  <Stack gap={1}>
                    <AtsTitleText
                      fs={14}
                      fw={600}
                      textColor="#000"
                      text={`${contractor.first_name} ${contractor.last_name}`}
                    />

                    <AtsTitleText
                      fs={12}
                      fw={500}
                      textColor={COLORS.LIGHT_GRAY}
                      text={selectedContractor?.title ?? ""}
                    />
                    <AtsTitleText
                      fs={12}
                      fw={500}
                      text={contractor?.team ?? "Java Team"}
                    />
                  </Stack>
                </Stack>
              </div>
            </Stack>
          </td>
        );
      case "email":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} gap={1}>
              <AtsTitleText
                fs={12}
                fw={400}
                textColor={"#000"}
                text={contractor[column.key]}
              />
            </Stack>
          </td>
        );

      case "action":
        return (
          <td style={cellStyles} key={column.key}>
            <AtsCustomButton px={0.6}>
              <AtsIcon icon="icon-eye" />
            </AtsCustomButton>
          </td>
        );
      default:
        return null;
    }
  };

  const renderRowComponent = () => {
    const invoicesList = contractors.map((value, index: number) => {
      return (
        <React.Fragment key={index}>
          <tr
            style={{
              borderBottom: BORDERS.GRAY,
              height: "68px",
              margin: "0px 8px",
              paddingTop: "10px",
              paddingBottom: "10px",
              cursor: "pointer",
              backgroundColor:
                value.candidate_id === hoveredRow ? "#f2f6ff" : "white",
            }}
            onMouseEnter={() => setHoveredRow(value.candidate_id)}
            onMouseLeave={() => {
              setHoveredRow(null);
            }}
          >
            {LEAD_COLUMN.map((val: IColumnProps) =>
              customizeRow(val, value, index)
            )}
          </tr>
        </React.Fragment>
      );
    });
    return invoicesList;
  };

  const headerComponent = () => {
    const headers = LEAD_COLUMN.map((column: IColumnProps, index: number) => (
      <>
        <th
          className="customTH"
          style={{
            width: column.width,
            minWidth: column.minWidth,
            padding: "15px 10px 5px 10px",
            textAlign: "start",
          }}
        >
          <Stack direction={"row"} alignItems={"center"} gap={2}>
            <AtsCustomButton px={0.5}>
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <AtsTitleText
                  text={column.header}
                  fs={14}
                  fw={600}
                  textColor="#4f4f4f"
                  isCursor
                />
                {column.header.length > 0 && <FilterIcon />}
              </Stack>
            </AtsCustomButton>
            {column.header.length > 0 && (
              <Stack>
                <KeyboardArrowUp
                  sx={{
                    color: "#4f4f4f",
                    fontSize: 16,
                    mb: -0.4,
                    cursor: "pointer",
                  }}
                />
                <KeyboardArrowDown
                  sx={{
                    color: "#4f4f4f",
                    fontSize: 16,
                    mt: -0.5,
                    cursor: "pointer",
                  }}
                />
              </Stack>
            )}
          </Stack>
        </th>
      </>
    ));
    return headers;
  };

  const backItem = () => {
    dispatch(setCurrentIndex(currentIndex - 1));
    const findProjectTab = projectTab.find(
      (_, index) => index === currentIndex - 1
    );
    dispatch(setSelectedProjectDetailsTab(findProjectTab));
  };

  return (
    <Stack
      gap={"10px"}
      sx={{
        height: "100%",
      }}
    >
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <AtsTitleText text={selectedProjectDetailsTab.name} fs={20} fw={600} />
      </Stack>
      <AtsTitleText
        fs={12}
        fw={400}
        textColor={COLORS.LIGHT_GRAY}
        text={selectedProjectDetailsTab.description}
      />

      <Box
        sx={{
          height: "calc(100% - 130px)",
          overflow: "auto",
        }}
      >
        <Stack gap={"25px"} mt={"20px"}>
          <Stack gap={"14px"}>
            <AtsTitleText
              fs={16}
              fw={500}
              text={`2 Active member(s)`}
              textColor={COLORS.PRIMARY}
            />
            <AtsCard sx={{ mb: "10px" }}>
              <Box
                sx={{
                  px: "19px",
                  pb: "27px",

                  // py: 2,
                }}
              >
                <AtsDataTable
                  // isTopBorder
                  data={invoicesData}
                  theadComponent={headerComponent}
                  rowComponent={renderRowComponent}
                  loading={loading}
                />
              </Box>
            </AtsCard>
          </Stack>
          <Stack gap={"14px"}>
            <AtsTitleText
              fs={16}
              fw={500}
              text={`2 Inactive member(s)`}
              textColor={COLORS.PRIMARY}
            />
            <AtsCard sx={{ mb: "10px" }}>
              <Box
                sx={{
                  px: "19px",
                  pb: "27px",

                  // py: 2,
                }}
              >
                <AtsDataTable
                  // isTopBorder
                  data={invoicesData}
                  theadComponent={headerComponent}
                  rowComponent={renderRowComponent}
                  loading={loading}
                />
              </Box>
            </AtsCard>
          </Stack>
        </Stack>
      </Box>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        pt={1}
      >
        <AtsRoundedButton
          text="Back"
          buttonVariant="outline"
          buttonHeight={34}
          buttonWidth={79}
          onClick={backItem}
        />

        <AtsRoundedButton
          text="Next"
          buttonHeight={34}
          buttonWidth={113}
          // onClick={nextItem}
        />
      </Stack>
    </Stack>
  );
};

export default TeamMember;

import { Avatar, Badge, Stack } from "@mui/material";
import AtsTitleText from "../../../../../components/AtsTitleText";
import { BG_COLORS, COLORS } from "../../../../../theme";
import { styled } from "@mui/material";

const UserInfo = () => {
  const online = false;
  return (
    <Stack
      direction={"row"}
      alignItems={"flex-start"}
      gap={"10px"}
      sx={{
        pl: "10px",
        pr: "10px",
        py: "15px",
        bgcolor: BG_COLORS.GRAY,
        borderRadius: "6px",
        cursor: "pointer",
      }}
    >
      {online ? (
        <StyledBadge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          variant="dot"
        >
          <Avatar />
        </StyledBadge>
      ) : (
        <Avatar />
      )}
      <Stack gap={"8px"} flex={1}>
        <AtsTitleText text={"Matthew Pritchard"} fw={400} />
        <AtsTitleText
          text={"Hello David ?"}
          fs={12}
          fw={400}
          textColor={COLORS.LIGHT_GRAY}
        />
      </Stack>
      <AtsTitleText
        text={"Sep 15"}
        fs={12}
        fw={400}
        textColor={COLORS.LIGHT_GRAY}
      />
    </Stack>
  );
};

export default UserInfo;

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

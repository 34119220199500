import { Box, Container, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AtsCustomButton from "../../components/AtsCustomButton";
import AtsIcon from "../../components/AtsIcon";
import AtsTitleText from "../../components/AtsTitleText";
import { ATS_ROUTES } from "../../router/routes";
import { COLORS } from "../../theme";
import ProjectCard from "./modules/ProjectModules/ProjectCard";
import ProjectFilterSection from "./modules/ProjectModules/ProjectFilterSection";
import ProjectTopContainer from "./modules/ProjectModules/ProjectTopContainer";
import { useEffect, useState } from "react";
import { ProjectService } from "../../Api/projectServices";
import { motion } from "framer-motion";
import { ProjectListParams, ProjectListProps } from "../../types/projectTypes";
import { useAppSelector } from "../../redux/store";
import { getProjectsState } from "../../redux/slices/projectsSlice";
import { getPercentageFromStartToEnd } from "../../utils/dateUtils";
import { debounce } from "lodash";
import { isNotNullOrUndefined } from "../../utils/stringUtils";

const Projects = () => {
  const navigate = useNavigate();
  const { projectFilter } = useAppSelector(getProjectsState);
  const [projects, setProjects] = useState<ProjectListProps[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getProjects = async () => {
    setIsLoading(true);
    try {
      const projectState = {
        status: projectFilter.status,
        ...(projectFilter.end_date.length && {
          end_date: projectFilter.end_date,
        }),
        ...(projectFilter.start_date.length && {
          start_date: projectFilter.start_date,
        }),
        ...(projectFilter.budget_high > 0 && {
          budget_high: projectFilter.budget_high,
        }),
        ...(projectFilter.budget_low > 0 && {
          budget_low: projectFilter.budget_low,
        }),
        ...(projectFilter.country_code.length && {
          country_code: projectFilter.country_code,
        }),
        ...(isNotNullOrUndefined(projectFilter?.title) &&
          projectFilter?.title.length && {
            title: projectFilter?.title,
          }),
      } as ProjectListParams;
      const response = await ProjectService.getProjectList(projectState);
      setProjects(response.results);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  const debouncedGetProjects = debounce(getProjects, 500);

  useEffect(() => {
    debouncedGetProjects();

    return () => {
      debouncedGetProjects.cancel();
    };
  }, [projectFilter]);

  return (
    <div
      className="main-container"
      style={{ height: "100%", paddingLeft: "10px", paddingRight: "10px" }}
    >
      <Container
        maxWidth="xl"
        sx={{
          mt: 3,
          mb: 4,
          pr: "14px !important",
        }}
      >
        <Stack sx={{ mb: 1.5, pr: "10px" }}>
          <ProjectTopContainer />
        </Stack>
        <Stack direction={"row"} alignItems={"center"} sx={{ pr: "10px" }}>
          <Stack
            direction={"row"}
            sx={{ minHeight: 35, minWidth: 305 }}
            alignItems={"center"}
            gap={0.3}
          >
            <AtsCustomButton px={0.5}>
              <AtsIcon icon="icon-setting-4" height="20px" fw={600} />
            </AtsCustomButton>
            <AtsTitleText
              text={"Filter"}
              fs={14}
              fw={500}
              textColor={COLORS.BLACK}
            />
          </Stack>
          <Stack
            direction={"row"}
            sx={{ minHeight: 35, flexGrow: 1 }}
            alignItems={"center"}
            justifyContent={"space-between"}
            gap={0.3}
          >
            <AtsTitleText
              text={"24 Project(s)"}
              fs={14}
              fw={500}
              textColor={COLORS.BLACK}
            />
            <Stack
              direction={"row"}
              sx={{ minHeight: 35 }}
              alignItems={"center"}
              gap={1}
            >
              <AtsTitleText
                text={"Sort by:"}
                fs={14}
                fw={500}
                textColor={COLORS.BLACK}
              />
              <AtsTitleText
                text={"Last used by me"}
                fs={14}
                fw={400}
                textColor={COLORS.BLACK}
              />
              <AtsCustomButton px={0.5}>
                <AtsIcon icon="icon-arrow-down-1" height="20px" fw={600} />
              </AtsCustomButton>
            </Stack>
          </Stack>
        </Stack>
        <Box
          sx={{
            height: "calc(100% - 156px)",
            mt: "10px",
          }}
        >
          <Stack direction={"row"} gap={"22px"} sx={{ height: "100%" }}>
            <Stack
              sx={{
                height: "98%",
                width: 272,
                bgcolor: "white",
                borderRadius: "10px",
                mt: "10px",
              }}
              gap={"30px"}
            >
              <ProjectFilterSection />
            </Stack>
            <Stack
              className="scrollHide"
              sx={{
                height: "97%",
                overflow: "auto",
                flex: 1,
                pl: "10px",
                pr: "10px",
                py: "10px",
              }}
              gap={"30px"}
            >
              {isLoading ? (
                <ProjectCard
                  projectId={""}
                  progress={20}
                  image={""}
                  projectName={""}
                  location={""}
                  pNumber={""}
                  description={""}
                  loading={isLoading}
                />
              ) : (
                projects.map((project) => (
                  <motion.div
                    key={project.nid}
                    whileHover={{ scale: 1.02, translateY: -5 }}
                    transition={{ duration: 0.3, ease: "easeInOut" }}
                    style={{
                      height: "100%",
                    }}
                  >
                    <ProjectCard
                      projectId={project.nid}
                      progress={
                        project?.start_date && project?.end_date
                          ? getPercentageFromStartToEnd(
                              project?.start_date,
                              project?.end_date
                            )
                          : 0
                      }
                      image={project.project_image}
                      projectName={project.title}
                      location={project.location?.country}
                      pNumber={project?.nid}
                      description={project?.description}
                      teamMember={project?.team_members}
                      loading={isLoading}
                      onClick={() =>
                        navigate(
                          ATS_ROUTES.PROJECT_DETAILS.replace(":id", project.nid)
                        )
                      }
                    />
                  </motion.div>
                ))
              )}
            </Stack>
          </Stack>
        </Box>
      </Container>
    </div>
  );
};

export default Projects;

import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import dayjs, { Dayjs } from "dayjs";
import { CreateUpdateInterviewParams } from "../../types/interviewTypes";

export enum TabSelect {
  SCHEDULE = "SCHEDULE",
  ATENDEE_DETAILS = "ATENDEE_DETAILS",
  SUCCESS_SCHEDULE = "SUCCESS_SCHEDULE",
}
type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

export const initialCreateInterviewState: CreateUpdateInterviewParams = {
  subject: "",
  description: "",
  candidate_email: "",
  interviewer_email: [],
  duration: "",
  job_id: "",
  date_time: "",
  status_id: 3429,
  type_id: 3919,
};
interface InterviewState {
  selectedDate: Value;
  count: number;
  isInterviewDetails: boolean;
  interviewTab: number;
  selectedSlot: string;
  nextTab: TabSelect;
  createInterview: Partial<CreateUpdateInterviewParams>;
}

const initialState: InterviewState = {
  selectedDate: new Date(),
  count: 15,
  interviewTab: 0,
  isInterviewDetails: false,
  selectedSlot: "",
  nextTab: TabSelect.ATENDEE_DETAILS,
  createInterview: initialCreateInterviewState,
};

const interviewSlice = createSlice({
  name: "interview",
  initialState,
  reducers: {
    setSelectedDate: (state: InterviewState, action) => {
      const { payload } = action;
      state.selectedDate = payload;
    },
    setCount: (state: InterviewState, action) => {
      const { payload } = action;
      state.count = payload;
    },
    setSelectedSlot: (state: InterviewState, action) => {
      const { payload } = action;
      state.selectedSlot = payload;
    },
    setNextTab: (state: InterviewState, action) => {
      const { payload } = action;
      state.nextTab = payload;
    },
    setInterviewTab: (state: InterviewState, action) => {
      const { payload } = action;
      state.interviewTab = payload;
    },
    setIsInterviewDetails: (state: InterviewState, action) => {
      const { payload } = action;
      state.isInterviewDetails = payload;
    },
    setCreateInterview: (state: InterviewState, action) => {
      const { payload } = action;
      state.createInterview = payload;
    },
  },
});

export const {
  setSelectedDate,
  setSelectedSlot,
  setCount,
  setNextTab,
  setInterviewTab,
  setIsInterviewDetails,
  setCreateInterview,
} = interviewSlice.actions;
export const getInterviewState = (state: RootState) => state.interview;
export default interviewSlice.reducer;

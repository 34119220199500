import React, { useEffect, useState } from "react";

import { Checkbox, Divider, Stack } from "@mui/material";

import {
  AccountCircle,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import AtsDataTable from "../../../../../../../components/AtsDataTable";
import AtsCheckbox from "../../../../../../../components/AtsCheckbox";
import AtsRoundedButton from "../../../../../../../components/AtsRoundedButton";
import AtsTitleText from "../../../../../../../components/AtsTitleText";
import AtsCustomButton from "../../../../../../../components/AtsCustomButton";
import AtsIcon from "../../../../../../../components/AtsIcon";
import { BORDERS, COLORS } from "../../../../../../../theme";
import { FilterIcon } from "../../../../../../../assets/icons/Icons";
import { DisputedStatusEnum } from "../../../../../../../enums/projectEnums";
import { InfoIcon } from "../../../../../../Jobs/assets/Icons";
import { useDisputeResolution } from "../../../../../../../providers/DisputeResolutionProvider";
import DisputeResolutionDrawer from "./DisputeResolutionDrawer";
import { useAppDispatch } from "../../../../../../../redux/store";

interface IColumnProps {
  key: string;
  header: string;
  width: string;
  minWidth: string;
}
interface UserProps {
  id: string;
  teamMember: string;
  teamManager: string;
  time: string;
  status: string;
  disputed: string;
}

const pendingUser: UserProps[] = [
  {
    id: "1",
    teamMember: "Alex Novak",
    teamManager: "Chris McGrath",
    time: "42.50",
    status: "Under review",
    disputed: "45.00",
  },
  {
    id: "2",
    teamMember: "Harish Singh Gupta",
    teamManager: "Jason Antonio",
    time: "45.50",
    status: "Disputed",
    disputed: "40.00",
  },
  {
    id: "3",
    teamMember: "Martina Frank",
    teamManager: "Jason Antonio",
    time: "44.00",
    status: "Resolved",
    disputed: "48.00",
  },
];

const LEAD_COLUMN: IColumnProps[] = [
  {
    key: "teamMember",
    header: "Team Memeber",
    width: "auto",
    minWidth: "300px",
  },

  {
    key: "teamManager",
    header: "Team Manager",
    width: "200px",
    minWidth: "200px",
  },
  {
    key: "time",
    header: "Total Time",
    width: "150px",
    minWidth: "150px",
  },
  {
    key: "disputed",
    header: "Disputed",
    width: "100px",
    minWidth: "100px",
  },
  {
    key: "status",
    header: "Status",
    width: "150px",
    minWidth: "150px",
  },

  {
    key: "action",
    header: "",
    width: "80px",
    minWidth: "80px",
  },
];

const cellStyles = {
  padding: "9px 10px",
};

const DisputedTimesheet = () => {
  const { openCloseDisputedResolutionDrawer } = useDisputeResolution();

  const viewDisputed = (timesheet: UserProps) => {
    openCloseDisputedResolutionDrawer();
  };
  const customizeRow = (column: IColumnProps, user: UserProps) => {
    switch (column.key) {
      case "teamMember":
      case "teamManager":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} gap={1}>
              <AtsTitleText
                fs={14}
                fw={400}
                textColor={COLORS.BLACK}
                text={user[column.key]}
              />
            </Stack>
          </td>
        );

      case "time":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} gap={1}>
              <AtsTitleText
                fs={14}
                fw={400}
                textColor={COLORS.BLACK}
                text={`${user.time} hrs`}
              />
            </Stack>
          </td>
        );
      case "disputed":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} gap={1}>
              <AtsTitleText
                fs={14}
                fw={400}
                textColor={COLORS.RED}
                text={`${parseInt(user.time) - parseInt(user.disputed)} hrs`}
              />
            </Stack>
          </td>
        );
      case "status":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} alignItems={"center"}>
              <AtsRoundedButton
                text={user.status}
                buttonVariant={
                  user.status === DisputedStatusEnum.Resolved
                    ? "greenContained"
                    : user.status === DisputedStatusEnum.Disputed
                    ? "primaryContained"
                    : "dangerContained"
                }
                buttonHeight={27}
                fs={12}
                fw={600}
              />
            </Stack>
          </td>
        );
      case "action":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} alignItems={"center"} gap={1.5}>
              <Stack direction={"row"} alignItems={"center"} gap={"15px"}>
                <AtsCustomButton px={0.6}>
                  <AtsIcon icon="icon-eye" height="16px" color={COLORS.BLACK} />
                </AtsCustomButton>
                <AtsCustomButton px={0.6} onClick={() => viewDisputed(user)}>
                  <InfoIcon strokeColor={COLORS.BLACK} />
                </AtsCustomButton>
              </Stack>
            </Stack>
          </td>
        );
      default:
        return null;
    }
  };

  const renderRowComponent = () => {
    const leadsData = pendingUser.map((value, index: number) => {
      return (
        <React.Fragment key={index}>
          <tr
            style={{
              borderBottom: BORDERS.GRAY,
              minHeight: "44px",
              margin: "0px 8px",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            {LEAD_COLUMN.map((val: IColumnProps) => customizeRow(val, value))}
          </tr>
        </React.Fragment>
      );
    });
    return leadsData;
  };

  const headerComponent = () => {
    const headers = LEAD_COLUMN.map((column: IColumnProps, index: number) => (
      <>
        <th
          className="customTH"
          style={{
            width: column.width,
            minWidth: column.minWidth,
            padding: "15px 10px",
            textAlign: "start",
          }}
        >
          <Stack direction={"row"} alignItems={"center"} gap={2}>
            <AtsCustomButton px={0.5}>
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <AtsTitleText
                  text={column.header}
                  fs={14}
                  fw={600}
                  textColor="#4f4f4f"
                  isCursor
                />
                {column.header.length > 0 && <FilterIcon />}
              </Stack>
            </AtsCustomButton>
            {column.header.length > 0 && (
              <Stack>
                <KeyboardArrowUp
                  sx={{
                    color: "#4f4f4f",
                    fontSize: 16,
                    mb: -0.4,
                    cursor: "pointer",
                  }}
                />
                <KeyboardArrowDown
                  sx={{
                    color: "#4f4f4f",
                    fontSize: 16,
                    mt: -0.5,
                    cursor: "pointer",
                  }}
                />
              </Stack>
            )}
          </Stack>
        </th>
      </>
    ));
    return headers;
  };
  return (
    <>
      <AtsDataTable
        data={pendingUser}
        theadComponent={headerComponent}
        rowComponent={renderRowComponent}
        loading={false}
      />
      <DisputeResolutionDrawer />
    </>
  );
};

export default DisputedTimesheet;

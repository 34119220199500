import { Close, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Modal,
  Skeleton,
  Stack,
} from "@mui/material";
import { RefreshIcon } from "../../../../../../assets/icons/Icons";
import AtsCustomButton from "../../../../../../components/AtsCustomButton";
import AtsIcon from "../../../../../../components/AtsIcon";
import AtsTitleText from "../../../../../../components/AtsTitleText";
import { BG_COLORS, BORDERS, COLORS } from "../../../../../../theme";
import {
  Attachment,
  CreateAndUpdateProjectDocsRequest,
  DocsProps,
} from "../../../../../../types/projectTypes";
import DragAndDrop from "./DragAndDrop";
import { useState } from "react";
import AtsRoundedButton from "../../../../../../components/AtsRoundedButton";
import { useParams } from "react-router-dom";
import { ProjectService } from "../../../../../../Api/projectServices";
import { useAppDispatch } from "../../../../../../redux/store";
import { setProjectDocsList } from "../../../../../../redux/slices/projectDetailsSlice";

interface IDocAccordionProps {
  expanded: boolean;
  loading?: boolean;
  showButton?: boolean;
  documents: DocsProps;
  handleChange: (event, isShow) => void;
  handleFileChange: (event) => void;
  handleDrop: (event) => void;
  deleteDocument: (document, selectedAttachment) => void;
  getDocumentList: () => void;
  removeAttachment: (index: number | string) => void;
  // files?: any[];
  setFile: React.Dispatch<React.SetStateAction<any>>;
}

const DocsAccordion = ({
  expanded,
  documents,
  loading,
  showButton,
  // files,
  // setFiles,
  handleChange,
  handleDrop,
  handleFileChange,
  deleteDocument,
  getDocumentList,
  removeAttachment,
  setFile,
}: IDocAccordionProps) => {
  const { id } = useParams();
  const [open, setOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const [selectedAttachment, setSelectedAttachment] =
    useState<Attachment | null>(null);
  // console.log("🚀 ~ files:", files);
  // const handleDelete = (index: number) => {
  //   const preFiles = [...files];
  //   preFiles.splice(index, 1);
  //   setFiles(preFiles);
  // };

  // const handleFiles = async (selectedFiles) => {
  //   const fileArray = Array.from(selectedFiles);
  //   setFiles([...files, ...fileArray]);

  //   // const updateDocument = [...requiredDocument, ...fileArray];
  //   // dispatch(setRequiredDocument(updateDocument));
  // };

  // const handleFileChange = (e) => {
  //   handleFiles(e.target.files);
  // };

  // const handleDrop = (e) => {
  //   e.preventDefault();
  //   handleFiles(e.dataTransfer.files);
  // };
  // console.log("documents.attachments", documents.attachments);
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const createAndUpdateDocument = async () => {
    // setLoading(true)
    const nid = documents?.nid || documents?.nid?.length ? documents?.nid : "";
    const document_type_id =
      typeof documents?.id === "string" ? Number(documents?.id) : documents?.id;
    const document_type_parent_id =
      typeof documents?.parent_id === "string"
        ? Number(documents?.parent_id)
        : documents?.parent_id;
    const title = documents?.name;
    const description = documents?.description;
    const attachments_fid = documents?.attachments.map((attachment) =>
      typeof attachment?.fid === "string"
        ? Number(attachment.fid)
        : attachment.fid
    );
    const document_urls = documents?.document_urls;

    const policyObj = {
      ...(nid?.length && { nid: Number(nid) }),
      project_id: Number(id),
      document_type_id,
      document_type_parent_id,
      title,
      description,
      attachments_fid,
      document_urls,
    } as Partial<CreateAndUpdateProjectDocsRequest>;
    if (documents?.nid?.length) {
      await ProjectService.updateProjectDocument(policyObj);
    } else {
      await ProjectService.createProjectDocument(policyObj);
    }
    getDocumentList();
    setFile(null);
    try {
    } catch (error) {
      console.error(error);
    } finally {
      // setLoading(false)
    }
  };

  const openModal = (attachment: Attachment) => {
    setOpen(true);
    setSelectedAttachment(attachment);
  };

  return (
    <>
      <Accordion
        disableGutters
        expanded={expanded}
        onChange={handleChange}
        sx={{
          "&.MuiPaper-root": {
            boxShadow: "none !important",
            borderBottom: BORDERS.GRAY,
            backgroundColor: "transparent",
            "::before": {
              bgcolor: "transparent ",
            },
          },
        }}
      >
        <AccordionSummary
          sx={{
            px: 0,
          }}
          expandIcon={<ExpandMore />}
        >
          <AtsTitleText
            text={documents.name}
            fs={16}
            fw={600}
            textColor={COLORS.PRIMARY}
          />
        </AccordionSummary>
        <AccordionDetails
          sx={{
            px: 0,
          }}
        >
          <Stack gap={"15px"}>
            <Stack gap={"7px"}>
              <AtsTitleText
                text={"Upload File"}
                textColor="#000"
                fs={13}
                fw={600}
              />
              <Stack
                gap={"10px"}
                sx={{
                  borderRadius: "10px",
                  border: "1px dashed #6d6d6d",
                }}
              >
                {loading ? (
                  <Skeleton variant="rounded" width={"100%"} height={51} />
                ) : (
                  <DragAndDrop
                    handleDragOver={handleDragOver}
                    handleDrop={handleDrop}
                    handleFileChange={handleFileChange}
                  />
                )}
                {/* <Stack
                  gap={"10px"}
                  sx={{
                    px: "10px",
                    py: "8px",
                  }}
                >
                  {files?.length > 0 &&
                    files?.map((file, index) => {
                      let fileName = file.name.split(".")[0];
                      if (fileName.length > 20) {
                        fileName = fileName.substring(0, 20) + "...";
                      }
                      console.log("fileName", fileName);
                      return (
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          justifyContent={"space-between"}
                          sx={{
                            bgcolor: BG_COLORS.GRAY,
                            px: "10px",
                            py: "8px",
                          }}
                        >
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            gap={"8px"}
                          >
                            <img
                              src={require("../../../../../../assets/images/pdfIcon.png")}
                              alt="Nvidia Logo"
                              style={{ width: 15 }}
                            />
                            <AtsTitleText
                              text={fileName}
                              textColor="#000"
                              fs={13}
                              fw={600}
                            />
                          </Stack>
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            gap={"8px"}
                          >
                            <AtsCustomButton
                              px={0.5}
                              py={0.5}
                              onClick={() => handleDelete(index)}
                            >
                              <AtsIcon icon="icon-trash" height="16px" />
                            </AtsCustomButton>
                          </Stack>
                        </Stack>
                      );
                    })}
                </Stack> */}
              </Stack>
              <AtsTitleText
                text={"Supported formats: PDF, docs, CSV"}
                textColor={COLORS.LIGHT_GRAY}
                fs={12}
                fw={500}
              />
            </Stack>
            {documents.attachments.map((attachment, index) => {
              return (
                <Stack
                  key={index}
                  sx={{
                    px: "10px",
                    py: "8px",
                    bgcolor: BG_COLORS.GRAY,
                    borderRadius: "6px",
                  }}
                >
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Stack direction={"row"} alignItems={"center"} gap={"8px"}>
                      <img
                        src={require("../../../../../../assets/images/pdfIcon.png")}
                        alt="Nvidia Logo"
                        style={{ width: 15 }}
                      />
                      <AtsTitleText
                        text={attachment.url.split("/").pop()}
                        textColor="#000"
                        fs={13}
                        fw={600}
                      />
                    </Stack>
                    {documents?.nid?.length > 0 ? (
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        gap={"8px"}
                      >
                        <AtsCustomButton px={0.5} py={0}>
                          <RefreshIcon />
                        </AtsCustomButton>
                        <Divider
                          variant="fullWidth"
                          orientation="vertical"
                          flexItem
                        />
                        <AtsCustomButton
                          px={0.5}
                          py={0.5}
                          onClick={() => openModal(attachment)}
                        >
                          <AtsIcon icon="icon-trash" height="16px" />
                        </AtsCustomButton>
                      </Stack>
                    ) : (
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        gap={"8px"}
                      >
                        <AtsCustomButton
                          px={0.5}
                          py={0.5}
                          onClick={() => removeAttachment(attachment.fid)}
                        >
                          <AtsIcon icon="icon-close-circle" height="16px" />
                        </AtsCustomButton>
                      </Stack>
                    )}
                  </Stack>
                </Stack>
              );
            })}

            <Stack
              sx={{
                position: "relative",
                mt: "30px",
                mb: "20px",
              }}
            >
              <Divider variant="fullWidth" orientation="horizontal" flexItem />
              <Box
                sx={{
                  position: "absolute",
                  left: "50%",
                  right: "50%",
                  bottom: -5,
                  width: "20px",
                  bgcolor: "white",
                  textAlign: "center",
                }}
              >
                <AtsTitleText text={"OR"} textColor="#000" fs={10} fw={400} />
              </Box>
            </Stack>
            <Stack gap={"7px"}>
              <AtsTitleText
                text={"Document URL"}
                textColor="#000"
                fs={13}
                fw={600}
              />
              <Stack
                sx={{
                  px: "10px",
                  py: "8px",
                  bgcolor: BG_COLORS.GRAY,
                  borderRadius: "6px",
                }}
              >
                <input
                  placeholder="Enter document url here"
                  type="text"
                  className="customInput"
                  style={{
                    backgroundColor: BG_COLORS.GRAY,
                    height: 22,
                  }}
                />
              </Stack>
            </Stack>
            {documents.document_urls.map((docs, index) => {
              return (
                <Stack
                  key={index}
                  sx={{
                    px: "10px",
                    py: "8px",
                    bgcolor: BG_COLORS.GRAY,
                    borderRadius: "6px",
                  }}
                >
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Stack direction={"row"} alignItems={"center"} gap={"8px"}>
                      <AtsTitleText
                        text={docs}
                        textColor="#000"
                        fs={13}
                        fw={600}
                      />
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"} gap={"8px"}>
                      <AtsCustomButton px={0.5} py={0}>
                        <RefreshIcon />
                      </AtsCustomButton>
                      <Divider
                        variant="fullWidth"
                        orientation="vertical"
                        flexItem
                      />
                      <AtsCustomButton px={0.5} py={0.5}>
                        <AtsIcon icon="icon-trash" height="16px" />
                      </AtsCustomButton>
                    </Stack>
                  </Stack>
                </Stack>
              );
            })}
            {showButton && (
              <Stack direction={"row"} alignItems={"center"}>
                <AtsRoundedButton
                  text={`Save ${documents.name} document`}
                  onClick={() => createAndUpdateDocument()}
                />
              </Stack>
            )}
          </Stack>
        </AccordionDetails>
      </Accordion>
      <Modal open={open} onClose={() => setOpen(!open)}>
        <Stack sx={style} gap={"20px"}>
          <AtsTitleText
            text={"Are you sure delete this file ?"}
            fs={20}
            fw={700}
          />
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{
              borderTop: BORDERS.GRAY,
              pt: 2,
            }}
          >
            <AtsRoundedButton
              buttonVariant="dangerContained"
              text="Delete"
              buttonWidth={100}
              buttonHeight={32}
              onClick={async () => {
                await deleteDocument(documents, selectedAttachment);
                setOpen(!open);
              }}
            />
            <AtsRoundedButton
              buttonVariant="outline"
              text="Cancel"
              buttonWidth={100}
              buttonHeight={32}
              onClick={() => setOpen(!open)}
            />
          </Stack>
        </Stack>
      </Modal>
    </>
  );
};

export default DocsAccordion;

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "6px",
  boxShadow: 24,
  p: 4,
};

import { createSlice } from "@reduxjs/toolkit";
import {
  CandidateListResponse,
  ContractorRoleResponse,
} from "../../types/projectTypes";
import { RootState } from "../store";

interface ContractorSliceState {
  selectedContractor: Partial<ContractorRoleResponse>;
  contractors: CandidateListResponse[];
  isContractorDetailsDrawerOpen: boolean;
}

const initialState: ContractorSliceState = {
  selectedContractor: {},
  contractors: [],
  isContractorDetailsDrawerOpen: false,
};

const contractorSlice = createSlice({
  name: "contractor",
  initialState,
  reducers: {
    setSelectedContractor: (state: ContractorSliceState, action) => {
      const { payload } = action;
      state.selectedContractor = payload;
    },
    setContractors: (state: ContractorSliceState, action) => {
      const { payload } = action;
      state.contractors = payload;
    },
    setIsContractorDetailsDrawerOpen: (state: ContractorSliceState, action) => {
      const { payload } = action;
      state.isContractorDetailsDrawerOpen = payload;
    },

    setInitialContractorDetailsState: () => initialState,
  },
});

export const {
  setSelectedContractor,
  setContractors,
  setIsContractorDetailsDrawerOpen,
  setInitialContractorDetailsState,
} = contractorSlice.actions;
export const getContractorDetailsState = (state: RootState) => state.contractor;
export default contractorSlice.reducer;

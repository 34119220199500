import { Stack, Typography } from "@mui/material";
import { Box } from "@mui/material";
import React from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../redux/store";
import {
  getContractorTimesheetState,
  setSelectedDisputeFlowTab,
  setShowDisputeModal,
} from "../../../../../../../../../redux/slices/contractorTimesheetSlice";
import FontUtil from "../../../../../../../../../utils/FontUtil";
import AtsRowBetween from "../../../../../../../../../components/AtsRowBetween";
import AtsTitleText from "../../../../../../../../../components/AtsTitleText";
import { BG_COLORS, BORDERS, COLORS } from "../../../../../../../../../theme";
import AtsRow from "../../../../../../../../../components/AtsRow";
import AtsRoundedButton from "../../../../../../../../../components/AtsRoundedButton";
import { DisputeTabsEnum } from "../../../../../../../../../types/contractorTypes";

const DisputedSuccessfully = () => {
  const dispatch = useAppDispatch();
  const { selectedContractorTimesheets } = useAppSelector(
    getContractorTimesheetState
  );
  const { font14 } = FontUtil();

  const onRemainingApprovHours = () => {
    dispatch(setShowDisputeModal(false));
    dispatch(setSelectedDisputeFlowTab(DisputeTabsEnum.DisputeEntries));
  };
  return (
    <>
      <Box
        sx={{
          px: "30px",
          pt: "20px",

          height: "calc(100% - 180px)",
        }}
      >
        <Stack
          sx={{
            height: "100%",
            overflow: "auto",
          }}
        >
          <Stack alignItems={"center"} mb={"20px"}>
            <img
              width={45}
              src={require("../../../../../../../../../assets/images/check.gif")}
              alt="check"
            />
          </Stack>
          <Stack textAlign={"center"} mb={"20px"}>
            <Typography
              sx={{
                ...font14,
                fontWeight: 400,
                color: "#000",
              }}
            >
              You have disputed{" "}
              <span style={{ fontWeight: 600 }}>
                {selectedContractorTimesheets.length} entries{" "}
              </span>
            </Typography>
          </Stack>
          <AtsRowBetween
            sx={{
              minHeight: 43,
              px: "14px",
              bgcolor: "#fff3f3",
              borderRadius: "6px",
              mb: "40px",
            }}
          >
            <>
              <AtsTitleText
                text={"Total time disputed"}
                fs={14}
                fw={600}
                textColor={"#e63030"}
              />
              <AtsTitleText
                text={"10.00 hrs"}
                fs={14}
                fw={600}
                textColor={"#e63030"}
              />
            </>
          </AtsRowBetween>
          <Stack gap={"9px"} mb={"40px"}>
            <AtsTitleText text={"Your Evidence"} fs={14} fw={600} />
            <AtsTitleText
              text={
                "This will be sent to the contractor and the account manager."
              }
              fs={12}
              fw={400}
              textColor={COLORS.DARK_GRAY}
            />
            <AtsRow columnGap={"15px"} rowGap={"10px"} flexWrap={"wrap"}>
              <>
                <Stack gap={"9px"}>
                  <Box
                    sx={{
                      border: BORDERS.GRAY,
                      borderRadius: "10px",
                      width: 79,
                      height: 80,
                    }}
                  ></Box>
                  <AtsTitleText
                    text={"contrac...ge.jpg"}
                    fs={11}
                    fw={400}
                    textColor={COLORS.LIGHT_GRAY}
                  />
                </Stack>
              </>
            </AtsRow>
          </Stack>
          <Stack gap={"9px"}>
            <AtsTitleText text={"Your note to Esteemed"} fs={14} fw={600} />
            <AtsTitleText
              text={
                "This will be sent to the contractor and the account manager."
              }
              fs={12}
              fw={400}
              textColor={COLORS.DARK_GRAY}
            />
            <AtsRowBetween
              sx={{
                minHeight: 43,
                px: "14px",
                bgcolor: BG_COLORS.GRAY,
                borderRadius: "6px",
              }}
            >
              <>
                <AtsTitleText
                  text={"Too many hours, extra 2 hours were not agreed."}
                  fs={14}
                  fw={400}
                  textColor={"#1c1c1c"}
                />
              </>
            </AtsRowBetween>
          </Stack>
        </Stack>
      </Box>
      <AtsRow pt={"20px"} pb={"40px"} justifyContent={"center"}>
        <AtsRoundedButton
          text="Approve remaining hours"
          fw={400}
          isInitial
          buttonWidth={226}
          buttonHeight={37}
          onClick={onRemainingApprovHours}
        />
      </AtsRow>
    </>
  );
};

export default DisputedSuccessfully;

import { InviteMemberProps } from "../redux/slices/inviteMembersSlice";
import { CompanyInfoResponse } from "../types/companyTypes";
import { CreateUpdateInterviewParams } from "../types/interviewTypes";
import { emailValidation } from "./common";
import { isNotNullOrUndefined } from "./stringUtils";

export interface AttendeeError {
  subject?: string;
  interviewer_email?: string;
  description?: string;
}

export interface ErrorMsg {
  error: AttendeeError;
  isValid: boolean;
}

export const attendeeDetailsValidation = (
  data: Partial<CreateUpdateInterviewParams>
): ErrorMsg => {
  const errorMsg: ErrorMsg = {
    error: {},
    isValid: true,
  };

  const validationRules: Record<string, [boolean, string]> = {
    subject: [!data.subject, "Please enter meeting name"],
    interviewer_email: [
      !data.interviewer_email.length,
      "Please select interviewer email",
    ],
    description: [!data.description, "Please enter description"],
  };

  for (const [field, [isInvalid, message]] of Object.entries(validationRules)) {
    if (isInvalid) {
      errorMsg.isValid = false;
      errorMsg.error[field] = message;
    }
  }

  return errorMsg;
};
export const inviteMemberValidation = (data: InviteMemberProps) => {
  const errorMsg = {
    error: {},
    isValid: true,
  };

  if (!data.role_id) {
    errorMsg.isValid = false;
    errorMsg.error["role_id"] = "Please select role";
  }
  if (!data.emails.length) {
    errorMsg.isValid = false;
    errorMsg.error["emails"] = "Please enter email address";
  }
  if (!data.emails.length) {
    errorMsg.isValid = false;
    errorMsg.error["emails"] = "Please enter email address";
  }
  return errorMsg;
};
export const companyDetailsValidation = (
  data: Partial<CompanyInfoResponse>,
  address: string
) => {
  const errorMsg = {
    error: {},
    isValid: true,
  };

  if (!data.title.length) {
    errorMsg.isValid = false;
    errorMsg.error["title"] = "Please enter company name";
  }

  if (!address.length) {
    errorMsg.isValid = false;
    errorMsg.error["address"] = "Please enter address";
  }

  if (!isNotNullOrUndefined(data.billing_contact)) {
    errorMsg.isValid = false;
    errorMsg.error["billing_contact"] = "Billing contact is required";
  } else {
    if (!data.billing_contact?.first_name?.length) {
      errorMsg.isValid = false;
      errorMsg.error[`billing_contact.first_name`] =
        "Please enter billing contact name";
    }
    if (!data.billing_contact?.email?.length) {
      errorMsg.isValid = false;
      errorMsg.error[`billing_contact.email`] =
        "Please enter billing contact email";
    }
    if (data.billing_contact?.email?.length) {
      if (!emailValidation(data.billing_contact?.email)) {
        errorMsg.isValid = false;
        errorMsg.error[`billing_contact.email`] =
          "Please enter valid billing contact email";
      }
    }
    if (!data.billing_contact?.phone?.length) {
      errorMsg.isValid = false;
      errorMsg.error[`billing_contact.phone`] =
        "Please enter billing contact phone number";
    }
  }

  if (!isNotNullOrUndefined(data.primary_contact)) {
    errorMsg.isValid = false;
    errorMsg.error["primary_contact"] = "Primary contact is required";
  } else {
    if (!data.primary_contact?.first_name?.length) {
      errorMsg.isValid = false;
      errorMsg.error[`primary_contact.first_name`] =
        "Please enter primary contact name";
    }
    if (!data.primary_contact?.email?.length) {
      errorMsg.isValid = false;
      errorMsg.error[`primary_contact.email`] =
        "Please enter primary contact email";
    }
    if (data.primary_contact.email?.length) {
      if (!emailValidation(data.primary_contact.email)) {
        errorMsg.isValid = false;
        errorMsg.error[`primary_contact.email`] =
          "Please enter valid primary contact email";
      }
    }
    if (!data.primary_contact?.phone?.length) {
      errorMsg.isValid = false;
      errorMsg.error[`primary_contact.phone`] =
        "Please enter primary contact phone number";
    }
  }

  return errorMsg;
};

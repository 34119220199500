import axios from "axios";
import {
  AllUserAvailabilityResponse,
  GetAvailabilityOfUsersRequest,
  GetDayAvailabilityResponse,
  GoogleCalendarEventResponse,
} from "../types/calendarTypes";
import { COLLEAGUES_DEV_ENDPOINT } from "../utils/url";

export const Endpoint = {
  BASE: "/ats/api/v1/",
  GET_AVILABILITY: "availability/get",
  SET_AVILABILITY: "set-availability",
  GET_AVILABILITY_OF_USERS: "get-availability",
  GET_UNAVILABILITY_OF_USERS: "get-un-available-timeslots",
  CALENDAR_CONNECTED: "google-auth/find",
  GET_ALL_CALENDAR_EVENTS: "interview/calendar/google",
};

const GET_AVILABILITY_URL = Endpoint.BASE + Endpoint.GET_AVILABILITY;
const GET_AVILABILITY_OF_USERS_URL =
  Endpoint.BASE + Endpoint.GET_AVILABILITY_OF_USERS;
const GET_UNAVILABILITY_OF_USERS_URL =
  Endpoint.BASE + Endpoint.GET_UNAVILABILITY_OF_USERS;
const SET_AVILABILITY_URL = Endpoint.BASE + Endpoint.SET_AVILABILITY;
const CALENDAR_CONNECTED_URL =
  COLLEAGUES_DEV_ENDPOINT + Endpoint.CALENDAR_CONNECTED;
const GET_ALL_CALENDAR_EVENTS_URL =
  COLLEAGUES_DEV_ENDPOINT + Endpoint.GET_ALL_CALENDAR_EVENTS;

export class CalendarServices {
  static async getAvailability(): Promise<GetDayAvailabilityResponse> {
    const response = await axios.get(GET_AVILABILITY_URL, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async calendarConnected() {
    const response = await axios.get(CALENDAR_CONNECTED_URL, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async setAvailability(
    request: Partial<GetDayAvailabilityResponse>
  ): Promise<GetDayAvailabilityResponse> {
    const response = await axios.post(SET_AVILABILITY_URL, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async getAvailabilityOfUsers(
    request: Partial<GetAvailabilityOfUsersRequest>
  ): Promise<AllUserAvailabilityResponse> {
    const response = await axios.post(GET_AVILABILITY_OF_USERS_URL, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async getUnAvailabilityOfUsers(
    request: Partial<GetAvailabilityOfUsersRequest>
  ): Promise<GetDayAvailabilityResponse> {
    const response = await axios.post(GET_UNAVILABILITY_OF_USERS_URL, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async getAllCalendarEventsOfUsers(
    request: Partial<GetAvailabilityOfUsersRequest>
  ): Promise<GoogleCalendarEventResponse[]> {
    const response = await axios.post(GET_ALL_CALENDAR_EVENTS_URL, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
}

import { Box, Modal, ModalProps } from "@mui/material";
import React from "react";

interface IModalProps extends ModalProps {
  children: JSX.Element;
  width?: number;
  height?: number;
}

const AtsModal = ({ children, width, height, ...rest }: IModalProps) => {
  return (
    <Modal {...rest}>
      <Box
        sx={{
          ...style,
          ...(width ? { width } : { width: 400 }),
          ...(height && { height }),
        }}
      >
        {children}
      </Box>
    </Modal>
  );
};

export default AtsModal;

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "6px",
  boxShadow: 24,
};

import { Stack } from "@mui/material";
import ChatRoom from "./modules/ChatRoom/ChatRoom";
import MessageLeftComponent from "./modules/MessageLeftside/MessageLeftComponent";

const Messaging = () => {
  return (
    <Stack direction={"row"}>
      <MessageLeftComponent />
      <ChatRoom />
    </Stack>
  );
};

export default Messaging;

import { Box, Collapse, Stack } from "@mui/material";
import AtsCustomButton from "../../../../../../../../components/AtsCustomButton";
import AtsIcon from "../../../../../../../../components/AtsIcon";
import AtsRow from "../../../../../../../../components/AtsRow";
import AtsTitleText from "../../../../../../../../components/AtsTitleText";
import { useDisputeResolution } from "../../../../../../../../providers/DisputeResolutionProvider";
import { pendingUser } from "../../../../../../../../redux/slices/contractorTimesheetSlice";
import { COLORS } from "../../../../../../../../theme";
import DisputedEntriesCard from "../../components/DisputedEntriescard";

const Summary = () => {
  const { show, setShow } = useDisputeResolution();
  return (
    <>
      <AtsTitleText text={"Summary"} fs={18} fw={600} textColor={"#000"} />
      <AtsRow gap={"89px"}>
        <>
          <Box
            sx={{
              width: 200,
            }}
          >
            <AtsTitleText
              text={"Timesheet Date:"}
              fs={14}
              fw={500}
              textColor={COLORS.DARK_GRAY}
            />
          </Box>
          <Box sx={{}}>
            <AtsTitleText
              text={"Aug 05, 2024 - Aug 11, 2024"}
              fs={14}
              fw={400}
              textColor={"#1c1c1c"}
            />
          </Box>
        </>
      </AtsRow>
      <Stack>
        <AtsRow gap={"89px"}>
          <>
            <Box
              sx={{
                width: 200,
              }}
            >
              <AtsTitleText
                text={"Disputed entries:"}
                fs={14}
                fw={500}
                textColor={COLORS.DARK_GRAY}
              />
            </Box>
            <AtsRow gap={"7.2px"} sx={{}}>
              <>
                <AtsTitleText
                  text={"3"}
                  fs={14}
                  fw={400}
                  textColor={"#1c1c1c"}
                />
                <AtsCustomButton px={0} py={0} onClick={() => setShow(!show)}>
                  <AtsRow gap={"4px"}>
                    <>
                      <AtsTitleText
                        text={show ? "hide" : "show"}
                        fs={12}
                        fw={500}
                        textColor={COLORS.PRIMARY}
                      />
                      <AtsIcon
                        icon={`icon-arrow-circle-${show ? "up" : "down"}`}
                        color={COLORS.PRIMARY}
                        height="16px"
                      />
                    </>
                  </AtsRow>
                </AtsCustomButton>
              </>
            </AtsRow>
          </>
        </AtsRow>
        <Collapse in={show}>
          <Box
            sx={{
              py: "16px",
              px: "14px",
            }}
          >
            <DisputedEntriesCard disputedEntries={pendingUser} />
          </Box>
        </Collapse>
      </Stack>
      <AtsRow gap={"89px"}>
        <>
          <Box
            sx={{
              width: 200,
            }}
          >
            <Stack gap={"7px"}>
              <AtsTitleText
                text={"Your note:"}
                fs={14}
                fw={500}
                textColor={COLORS.DARK_GRAY}
              />
              <AtsTitleText
                text={
                  "This is forwarded to the contractor and the accounting manager"
                }
                fs={11}
                fw={400}
                textColor={COLORS.DARK_GRAY}
              />
            </Stack>
          </Box>
          <Box sx={{}}>
            <AtsTitleText
              text={"02.45 hrs"}
              fs={14}
              fw={400}
              textColor={"#e63030"}
            />
          </Box>
        </>
      </AtsRow>
    </>
  );
};

export default Summary;

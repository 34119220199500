import { Stack } from "@mui/material";
import AtsIcon from "../../../../components/AtsIcon";
import AtsTextInput from "../../../../components/AtsTextInput";
import AtsTitleText from "../../../../components/AtsTitleText";
import { COLORS } from "../../../../theme";
import FilterDateRange from "../../components/FilterDateRange";
import FilterLoacation from "../../components/FilterLoacation";
import FilterBudgetRange from "../../components/FilterBudgetRange";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import {
  getProjectsState,
  setProjectFilter,
} from "../../../../redux/slices/projectsSlice";
import { ProjectFilterState } from "../../../../types/projectTypes";

const ProjectFilterSection = () => {
  const disaptch = useAppDispatch();
  const { projectFilter } = useAppSelector(getProjectsState);
  return (
    <Stack
      px={"20px"}
      my={"26px"}
      gap={"30px"}
      sx={{ height: "90%", overflow: "auto" }}
    >
      <Stack gap={"10px"}>
        <AtsTitleText
          text={"Search project"}
          fs={14}
          fw={600}
          textColor={COLORS.BLACK}
        />
        <AtsTextInput
          height="42px"
          placeholder="Search project name,project..."
          endAdornment={<AtsIcon icon="icon-search-normal-1" height="18px" />}
          value={projectFilter?.title}
          onChange={(e) => {
            const updateProjectFilter = {
              ...projectFilter,
              title: e.target.value,
            } as ProjectFilterState;
            disaptch(setProjectFilter(updateProjectFilter));
          }}
        />
      </Stack>
      <Stack gap={"10px"}>
        <FilterDateRange />
      </Stack>
      <Stack gap={"10px"}>
        <FilterLoacation />
      </Stack>
      <Stack gap={"10px"}>
        <FilterBudgetRange />
      </Stack>
    </Stack>
  );
};

export default ProjectFilterSection;

import { Skeleton, Stack } from "@mui/material";
import React from "react";
import AtsTitleText from "./AtsTitleText";
import { COLORS } from "../theme";

interface ITextareaProps {
  header?: string;
  id?: string;
  name?: string;
  rows?: number;
  cols?: number;
  placeholder?: string;
  disabled?: boolean;
  loading?: boolean;
  isBlackText?: boolean;
  readOnly?: boolean;
  maxLength?: number;
  autoFocus?: boolean;
  wrap?: "soft" | "hard" | "off";
  form?: string;
  spellCheck?: boolean;
  style?: React.CSSProperties;
  required?: boolean;
  value?: string;
  className?: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const AtsTextArea = ({
  header,
  id,
  name,
  rows,
  readOnly,
  cols,
  placeholder,
  disabled,
  spellCheck,
  wrap,
  required,
  form,
  style,
  value,
  className,
  onChange,
  autoFocus,
  maxLength,
  isBlackText,
  loading,
}: ITextareaProps) => {
  return (
    <Stack gap={1}>
      {header && (
        <AtsTitleText
          text={header}
          fs={"0.875rem"}
          fw={500}
          textColor={isBlackText ? COLORS.BLACK : COLORS.LIGHT_GRAY}
        />
      )}
      {loading ? (
        <Skeleton variant="text" width={"100%"} height={100} />
      ) : (
        <textarea
          id={id}
          className={className ?? "textArea"}
          name={name}
          rows={rows}
          cols={cols}
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readOnly}
          maxLength={maxLength}
          autoFocus={autoFocus}
          wrap={wrap}
          form={form}
          spellCheck={spellCheck}
          style={style}
          required={required}
          value={value}
          onChange={onChange}
        />
      )}
    </Stack>
  );
};

export default AtsTextArea;

import { Box } from "@mui/material";
import React from "react";
import { BORDERS } from "../../../../theme";
import ChatRoomTopSection from "./components/ChatRoomTopSection";

const ChatRoom = () => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "white",
      }}
    >
      <Box
        sx={{
          px: "30px",
          py: "20px",
          borderBottom: BORDERS.GRAY,
        }}
      >
        <ChatRoomTopSection />
      </Box>
    </Box>
  );
};

export default ChatRoom;

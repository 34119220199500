import { Divider, IconButton, Skeleton, Stack } from "@mui/material";
import React from "react";
import AtsTitleText from "../../../../../../../components/AtsTitleText";
import { BORDERS, COLORS } from "../../../../../../../theme";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../redux/store";
import { getSubmissionState } from "../../../../../../../redux/slices/submissionSlice";
import {
  ChevronLeft,
  ChevronRight,
  Close,
  MoreVert,
} from "@mui/icons-material";
import { setIsContractorDetailsDrawerOpen } from "../../../../../../../redux/slices/contractorSlice";
import { onCloseContractorDetailsDrawer } from "../ContractorDetailsDrawer";
import AtsRoundedButton from "../../../../../../../components/AtsRoundedButton";
import { ScheduleIcon } from "../../../../../../../assets/icons/Icons";
import ThreeDotIcon from "../../../../../../../assets/icons/threeDot.svg";
import Xfhghggh from "../../../../../../../assets/images/candidate.png";
import StarIconRating from "../../../../../../../assets/images/startIcon.svg";
import ThumbUp from "../../../../../../../assets/images/thumbup.svg";
import TopCandidate from "../../../../../../../assets/images/topCandidate.svg";
import { isNotNullOrUndefined } from "../../../../../../../utils/stringUtils";
import { getDateFormatMMMMDYYYYY } from "../../../../../../../utils/dateUtils";

const ContractorDetailsTopSection = () => {
  const dispatch = useAppDispatch();
  const isContractorDetailsLoading = false;
  return (
    <>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{ borderBottom: BORDERS.GRAY, px: "30px", height: 65 }}
      >
        <AtsTitleText
          text={"Contractor Detail"}
          fs={22}
          fw={600}
          textColor={COLORS.BLACK}
        />
        <Stack direction={"row"} alignItems={"center"} gap={"20px"}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            gap={0.3}
            sx={{ cursor: "pointer" }}
          >
            <AtsTitleText fs={12} fw={600} textColor={"#000"} text="1" />
            <AtsTitleText fs={12} fw={400} textColor={"#000"} text="of" />
            <AtsTitleText fs={12} fw={600} textColor={"#000"} text="59" />
            <AtsTitleText
              fs={12}
              fw={400}
              textColor={"#000"}
              text="Contractors"
            />
          </Stack>
          <Stack direction={"row"} alignItems={"center"} gap={0.3}>
            <ChevronLeft
              sx={{ width: "17px", height: "39px", color: "#6d6d6d" }}
            />
            <AtsTitleText
              fs={12}
              fw={400}
              textColor={COLORS.LIGHT_GRAY}
              text="Prev"
            />
          </Stack>
          <Stack direction={"row"} alignItems={"center"} gap={0.3}>
            <AtsTitleText
              fs={12}
              fw={400}
              textColor={COLORS.LIGHT_GRAY}
              text="Next"
            />
            <ChevronRight
              sx={{ width: "17px", height: "39px", color: "#000" }}
            />
          </Stack>
          <IconButton
            sx={{
              p: "5px",
            }}
            color="inherit"
            onClick={() => onCloseContractorDetailsDrawer(dispatch)}
          >
            <Close
              sx={{
                fontSize: "25px",
              }}
            />
          </IconButton>
        </Stack>
      </Stack>

      <Stack
        py={2}
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"flex-start"}
        flexWrap={"wrap"}
        sx={{ borderBottom: 1, borderColor: "#f5f5f5", pl: 4, pr: 4 }}
      >
        <Stack direction={"row"} alignItems={"center"} gap={"16px"}>
          <Stack>
            {isContractorDetailsLoading ? (
              <Skeleton variant="circular" width={110} height={110} />
            ) : isNotNullOrUndefined(null) ? (
              <img
                src={Xfhghggh}
                alt="Nvidia Logo"
                style={{
                  height: "auto",

                  width: "97px",
                  borderRadius: "100%",
                }}
              />
            ) : (
              <img
                src={Xfhghggh}
                alt="Nvidia Logo"
                style={{
                  height: "auto",

                  width: "97px",
                  borderRadius: "100%",
                }}
              />
            )}
          </Stack>
          <Stack gap={"6px"}>
            {isContractorDetailsLoading ? (
              <Skeleton width={150} height={20} />
            ) : (
              <AtsTitleText
                fs={18}
                fw={700}
                textColor={"#000"}
                text={`${"Alex" ?? ""} ${"Novak" ?? ""}`}
              />
            )}
            <AtsTitleText
              fs={12}
              fw={400}
              textColor={COLORS.LIGHT_GRAY}
              text={"Sr. Java developer"}
            />
            <AtsTitleText
              fs={12}
              fw={400}
              textColor={"#000"}
              text={"Java Team"}
            />
            <Stack direction={"row"} alignItems={"center"} gap={"10px"}>
              <Stack direction={"row"} alignItems={"center"} gap={0.3}>
                <AtsTitleText
                  fs={12}
                  fw={400}
                  textColor={"#000"}
                  text={"4.8"}
                />
                <img
                  src={StarIconRating}
                  alt="Nvidia Logo"
                  style={{ width: 15 }}
                />
              </Stack>
              <Divider variant="fullWidth" orientation="vertical" flexItem />
              <Stack direction={"row"} alignItems={"center"} gap={0.3}>
                <AtsTitleText
                  fs={12}
                  fw={400}
                  textColor={COLORS.LIGHT_GRAY}
                  text="Onboarded:"
                />
                <AtsTitleText
                  fs={12}
                  fw={400}
                  textColor={"#000"}
                  text={getDateFormatMMMMDYYYYY(new Date().toString())}
                />
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        <Stack gap={"44px"} alignItems={"flex-end"}>
          <Stack direction={"row"} alignItems={"center"} gap={2}>
            <AtsRoundedButton
              buttonWidth={"173px"}
              text="Review timesheet"
              isInitial
              startIcon={<ScheduleIcon />}
            />

            <IconButton sx={{ p: 0, marginRight: "-10px" }}>
              <MoreVert />
            </IconButton>
          </Stack>
          <Stack direction={"row"} alignItems={"center"} gap={"40px"}>
            <Stack direction={"row"} alignItems={"center"} gap={"6px"}>
              <AtsTitleText
                fs={14}
                fw={400}
                textColor={COLORS.LIGHT_GRAY}
                text="Work Mode"
              />
              <AtsTitleText
                fs={14}
                fw={400}
                textColor={COLORS.BLACK}
                text="Full-time"
              />
            </Stack>
            <Stack direction={"row"} alignItems={"center"} gap={"6px"}>
              <AtsTitleText
                fs={14}
                fw={400}
                textColor={COLORS.LIGHT_GRAY}
                text="Rate"
              />
              <AtsTitleText
                fs={14}
                fw={400}
                textColor={COLORS.BLACK}
                text="$40/hr"
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default ContractorDetailsTopSection;

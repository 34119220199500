import { Box, Grid, Stack } from "@mui/material";
import { BORDERS } from "../../../theme";
import AddSpecificHours from "./SetAvailability/AddSpecificHours";
import ConnectGoogleCalendar from "./SetAvailability/ConnectGoogleCalendar";
import SetBusinessHours from "./SetAvailability/SetBusinessHours";
import WeeklyAvailability from "./SetAvailability/WeeklyAvailability";

const SetAvailability = () => {
  return (
    <>
      <Grid container columnSpacing={"20px"} height={"100%"}>
        <Grid item xs={12} sm={6} md={8} lg={8} xl={9} xxl={10} height={"100%"}>
          <WeeklyAvailability />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4} xl={3} xxl={2}>
          <Box
            sx={{
              pt: "30px",
              px: "30px",
              pb: "53px",
              border: BORDERS.GRAY,
              borderRadius: "6px",
            }}
          >
            <Stack
              sx={{
                pb: "29px",
                borderBottom: BORDERS.GRAY,
              }}
            >
              <ConnectGoogleCalendar />
            </Stack>
            <Stack
              sx={{
                py: "30px",
                borderBottom: BORDERS.GRAY,
              }}
            >
              <SetBusinessHours />
            </Stack>
            <Stack
              sx={{
                pt: "30px",
              }}
            >
              <AddSpecificHours />
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default SetAvailability;

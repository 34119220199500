export const URL = {
  DEV: "https://dev-dashboard.esteemed.io",
  CURRENT: window.location.hostname,
  LOCAL: "localhost",
};
export const isDevEnv = URL.CURRENT === URL.DEV;

const ESTEEMED_DEV_API_GATEWAY = "https://dev-aura.esteemed.io/";
export const API_GATEWAY = ESTEEMED_DEV_API_GATEWAY;
export const WEB_SOCKET = "https://cdev.esteemed.io/v1/";
export const COLLEAGUES_DEV_ENDPOINT = "https://cdevapi.esteemed.io/v1/";
export const DEV_URL = "https://dev-dashboard.esteemed.io/";

import { Box, Stack, Tab, Tabs } from "@mui/material";
import AtsText from "../../../../../../../../components/AtsText";
import {
  getInterviewState,
  setInterviewTab,
  setIsInterviewDetails,
  setNextTab,
  TabSelect,
} from "../../../../../../../../redux/slices/interviewSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../redux/store";
import { COLORS } from "../../../../../../../../theme";
import AttendeeDetails from "./AttendeeDetails";
import InterviewDateTimeSelection from "./InterviewDateTimeSelection";
import InterviewScheduled from "./InterviewScheduled";
import AtsTabs from "../../../../../../../../components/AtsTabs";
import InterviewList from "./InterviewList";
import { ArrowForwardIos, ChevronLeft } from "@mui/icons-material";
import AtsTitleText from "../../../../../../../../components/AtsTitleText";
import AtsRoundedButton from "../../../../../../../../components/AtsRoundedButton";
import { dateFormatDDDDMMMMD } from "../../../../../../../../utils/dateUtils";
import { useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import AtsIcon from "../../../../../../../../components/AtsIcon";
import { TimerIcon } from "../../../../../../../../assets/icons/Icons";
import { attendeeDetailsValidation } from "../../../../../../../../utils/validationUtils";
import moment from "moment";
import { useState } from "react";

const InterviewTab = () => {
  const dispatch = useAppDispatch();
  const {
    nextTab,
    interviewTab,
    count,
    selectedDate,
    selectedSlot,
    createInterview,
  } = useAppSelector(getInterviewState);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [error, setError] = useState({});

  const convertDateAndTime = () => {
    let dateString: string = moment(selectedDate as any).format("DD-MM-YYYY");
    let timeString: string = selectedSlot;

    let dateParts: string[] = dateString.split("-");
    let day: number = parseInt(dateParts[0]);
    let month: number = parseInt(dateParts[1]) - 1;
    let year: number = parseInt(dateParts[2]);

    let date: Date = new Date(year, month, day);

    let timeParts: string[] = timeString.split(" ");
    let time: string[] = timeParts[0].split(":");
    let hours: number = parseInt(time[0]);
    let minutes: number = parseInt(time[1]);

    if (timeParts[1] === "PM" && hours !== 12) {
      hours += 12;
    } else if (timeParts[1] === "AM" && hours === 12) {
      hours = 0;
    }

    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(0);

    let isoString: string = date.toISOString().split(".")[0];
    return isoString;
  };

  const scheduleEvent = async () => {
    let validation = await attendeeDetailsValidation(createInterview);
    setError(validation.error);
    if (validation.isValid) {
      try {
        dispatch(setNextTab(TabSelect.SCHEDULE));
      } catch (error) {}
    }
  };

  const disabled = () => {
    if (count === 0 || !selectedSlot.length) return true;
    return false;
  };

  const commonStackStyles = {
    px: 3,
    py: 1,
    mt: 2,
    bgcolor: "#f5f5f5",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: 2,
  };

  const renderButtons = () => (
    <>
      <AtsRoundedButton
        text="View Event on Calendar"
        endIcon={<ArrowForwardIos sx={{ fontSize: "13px !important" }} />}
        buttonVariant="contained"
      />
      <AtsRoundedButton
        onClick={() => {
          dispatch(setInterviewTab(1));
          dispatch(setIsInterviewDetails(true));
        }}
        text="Interview Details"
        endIcon={<ArrowForwardIos sx={{ fontSize: "13px !important" }} />}
        buttonVariant="outline"
        isOutlineTransparent
      />
    </>
  );

  const renderDetails = () => (
    <Stack direction={"row"} sx={{ alignItems: "center" }} gap={"21px"}>
      <Stack direction={"row"} sx={{ alignItems: "center" }} gap={"8px"}>
        <TimerIcon />
        <AtsTitleText
          text={nextTab === TabSelect.ATENDEE_DETAILS ? "--" : `${count} Mins`}
          fs={14}
          fw={400}
          textColor={"#000"}
        />
      </Stack>
      <Stack direction={"row"} sx={{ alignItems: "center" }} gap={"8px"}>
        <AtsIcon icon="icon-calendar-1" color={COLORS.PRIMARY} height="16px" />
        <AtsTitleText
          text={
            nextTab === TabSelect.ATENDEE_DETAILS
              ? "--"
              : dateFormatDDDDMMMMD(selectedDate)
          }
          fs={14}
          fw={400}
          textColor={"#000"}
        />
      </Stack>
      <Stack direction={"row"} sx={{ alignItems: "center" }} gap={"8px"}>
        <AtsIcon icon="icon-clock" color={COLORS.PRIMARY} height="16px" />
        <AtsTitleText
          text={selectedSlot.length ? selectedSlot : "--"}
          fs={14}
          fw={400}
          textColor={"#000"}
        />
      </Stack>
    </Stack>
  );

  const renderNextOrScheduleButton = () =>
    nextTab === TabSelect.SCHEDULE ? (
      <Stack direction={"row"} alignItems={"center"} gap={5}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          sx={{ cursor: "pointer" }}
          onClick={() => dispatch(setNextTab(TabSelect.ATENDEE_DETAILS))}
        >
          <ChevronLeft sx={{ fontSize: 22, color: "#3366cc" }} />
          <AtsTitleText
            fs={12}
            textColor={COLORS.PRIMARY}
            fw={500}
            text="Go Back"
          />
        </Stack>
        <AtsRoundedButton
          disabled={disabled()}
          // onClick={scheduleEvent}
          text="Schedule Event"
          endIcon={<ArrowForwardIos sx={{ fontSize: "13px !important" }} />}
          variant="contained"
          buttonWidth={"170px"}
        />
      </Stack>
    ) : (
      <AtsRoundedButton
        onClick={() => {
          scheduleEvent();
        }}
        text="Next"
        endIcon={<ArrowForwardIos sx={{ fontSize: "13px !important" }} />}
        variant="contained"
        buttonWidth={"170px"}
      />
    );

  console.log("nextTab", nextTab);
  return (
    <>
      <Stack sx={{ height: "100%" }}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Stack direction={"row"} alignItems={"center"} gap={1}>
            <AtsText
              text="Schedule Interview"
              sx={{ fontSize: 20, fontWeight: 600, color: COLORS.BLACK }}
            />
          </Stack>
        </Stack>
        <AtsTabs
          value={interviewTab}
          onChange={(event, newValue) => {
            dispatch(setInterviewTab(newValue));
            dispatch(setNextTab(0));
            dispatch(setIsInterviewDetails(false));
          }}
          tabData={["Schedule New", "Interviews"]}
        />
        <Box
          sx={{
            height: "calc(100% - 80px)",
            overflow: "scroll",
            pr: 1,
          }}
        >
          {interviewTab === 0 ? (
            nextTab === TabSelect.SCHEDULE ? (
              <InterviewDateTimeSelection />
            ) : nextTab === TabSelect.SUCCESS_SCHEDULE ? (
              <InterviewScheduled />
            ) : (
              <AttendeeDetails error={error} />
            )
          ) : (
            <InterviewList />
          )}
        </Box>
        {interviewTab === 0 && (
          <Box>
            {nextTab === TabSelect.SUCCESS_SCHEDULE ? (
              <Stack direction={"row"} sx={commonStackStyles}>
                {renderButtons()}
              </Stack>
            ) : (
              <Stack
                direction={isMobile ? "column" : "row"}
                sx={commonStackStyles}
              >
                {renderDetails()}
                {renderNextOrScheduleButton()}
              </Stack>
            )}
          </Box>
        )}
      </Stack>
    </>
  );
};

export default InterviewTab;

import { Box, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import AtsTitleText from "../../../../../../components/AtsTitleText";
import {
  getProjectDetailsState,
  setCurrentIndex,
  setProjectDocsList,
  setSelectedProjectDetailsTab,
} from "../../../../../../redux/slices/projectDetailsSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/store";
import DocsAccordion from "../components/DocsAccordion";
import { ProjectService } from "../../../../../../Api/projectServices";
import {
  CreateAndUpdateProjectDocsRequest,
  DocsProps,
} from "../../../../../../types/projectTypes";
import { isNotNullOrUndefined } from "../../../../../../utils/stringUtils";
import { useParams } from "react-router-dom";
import AtsRoundedButton from "../../../../../../components/AtsRoundedButton";

const PolicyDocuments = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const {
    projectDocuments,
    selectedProjectDetailsTab,
    projectDocsList,
    currentIndex,
    projectTab,
  } = useAppSelector(getProjectDetailsState);
  const [expandedPanels, setExpandedPanels] = useState([]);
  const [policyDocs, setPolicyDocs] = useState<DocsProps[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [file, setFile] = useState(null);

  useEffect(() => {
    setExpandedPanels([]);
  }, [selectedProjectDetailsTab]);

  const isDocs = (id: string) => {
    return projectDocsList?.some((docs) => docs.document_type_id === id);
  };

  useEffect(() => {
    const filteredProjectDocs = projectDocuments
      .filter((docs) => docs.parent_id === selectedProjectDetailsTab.id)
      .map((docs) => {
        if (isDocs(docs.id)) {
          const findList = projectDocsList.find(
            (document) => document.document_type_parent_id === docs.parent_id
          );
          const attachments = findList.attachments;
          const document_urls = findList.document_urls;
          const nid = findList.nid;
          return {
            ...docs,
            attachments,
            document_urls,
            nid,
          };
        } else {
          return {
            ...docs,
            attachments: [],
            document_urls: [],
            nid: "",
          };
        }
      });
    setPolicyDocs(filteredProjectDocs);
  }, [selectedProjectDetailsTab, projectDocsList]);

  const getDocumentList = async () => {
    try {
      const response = await ProjectService.getDocumentByProjectId({
        project_id: id,
      });

      const documents = response.results.map((docs) => ({
        ...docs,
        attachments: docs.attachments.map((attachment) => ({
          ...attachment,
          nid: docs.nid,
        })),
      }));
      dispatch(setProjectDocsList(documents));
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const handleChange = (panelId) => (event, isExpanded) => {
    if (isExpanded) {
      setExpandedPanels((prevExpanded) => [...prevExpanded, panelId]);
    } else {
      setExpandedPanels((prevExpanded) =>
        prevExpanded.filter((id) => id !== panelId)
      );
    }
  };

  const handleBinaryUpload = async (file) => {
    if (!file) return;

    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = async function (event) {
        try {
          const arrayBuffer = event.target.result as ArrayBuffer;
          const data = await ProjectService.uploadDocumentForProject(
            arrayBuffer,
            file?.name
          );
          resolve(data);
        } catch (error) {
          console.error("Error uploading binary data:", error);
          reject(error);
        } finally {
          setLoading(false);
        }
      };

      reader.onerror = (error) => {
        console.error("Error reading file:", error);
        reject(error);
      };

      reader.readAsArrayBuffer(file);
    });
  };

  const handleFiles = async (selectedFiles, index) => {
    setLoading(true);
    const fileArray = Array.from(selectedFiles);

    const uploadRequirmentPromises = fileArray.map(handleBinaryUpload);
    const resultsForDoc: any = await Promise.all(uploadRequirmentPromises);
    const file = resultsForDoc.map((item) => ({
      fid: item?.fid[0]?.value || null,
      url: item?.uri[0]?.value || "",
    }));
    const prePolicyDocs = [...policyDocs];
    const policy = { ...policyDocs[index] };

    const updatePolicy = {
      ...policy,
      attachments: [...policy.attachments, ...file],
    } as DocsProps;
    prePolicyDocs.splice(index, 1, updatePolicy);
    setPolicyDocs(prePolicyDocs);
    setLoading(false);
    setFile({ file: fileArray, index });
  };

  const handleFileChange = (e, index) => {
    handleFiles(e.target.files, index);
  };

  const handleDrop = (e, index) => {
    e.preventDefault();
    handleFiles(e.dataTransfer.files, index);
  };

  const deleteDocument = async (documents: DocsProps, selectedAttachment) => {
    // setLoading(true)
    const nid =
      typeof documents?.nid === "string"
        ? Number(documents?.nid)
        : documents?.nid;
    const document_type_id =
      typeof documents?.id === "string" ? Number(documents?.id) : documents?.id;
    const document_type_parent_id =
      typeof documents?.parent_id === "string"
        ? Number(documents?.parent_id)
        : documents?.parent_id;
    const title = documents?.name;
    const description = documents?.description;
    const attachments_fid = documents?.attachments
      .filter((preAttachment) => preAttachment.fid !== selectedAttachment.fid)
      .map((attachment) =>
        typeof attachment?.fid === "string"
          ? Number(attachment.fid)
          : attachment.fid
      );
    const document_urls = documents?.document_urls;

    const policyObj = {
      nid,
      project_id: Number(id),
      document_type_id,
      document_type_parent_id,
      title,
      description,
      attachments_fid,
      document_urls,
    } as Partial<CreateAndUpdateProjectDocsRequest>;
    await ProjectService.updateProjectDocument(policyObj);
    const filterPolicy = policyDocs.map((policy) => ({
      ...policy,
      attachments: policy.attachments.filter(
        (attachment) => attachment.fid !== selectedAttachment?.fid
      ),
    }));
    setPolicyDocs(filterPolicy);
    try {
    } catch (error) {
      console.error(error);
    } finally {
      // setOpen(false);
    }
  };

  const removeAttachment = (docInx, index) => {
    const spreadPolicy = [...policyDocs];
    const prePolicy = { ...policyDocs[index] };
    const spreadAttachment = [...prePolicy.attachments];
    const preAttachment = {
      ...prePolicy,
      attachments: spreadAttachment.filter((attach) => attach.fid !== docInx),
    } as DocsProps;
    spreadPolicy.splice(index, 1, preAttachment);
    setPolicyDocs(spreadPolicy);
  };

  const backItem = () => {
    dispatch(setCurrentIndex(currentIndex - 1));
    const findProjectTab = projectTab.find(
      (_, index) => index === currentIndex - 1
    );
    dispatch(setSelectedProjectDetailsTab(findProjectTab));
  };
  const nextItem = () => {
    dispatch(setCurrentIndex(currentIndex + 1));
    const findProjectTab = projectTab.find(
      (_, index) => index === currentIndex + 1
    );
    dispatch(setSelectedProjectDetailsTab(findProjectTab));
  };

  return (
    <>
      <Stack gap={"10px"} height={"100%"}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <AtsTitleText
            text={selectedProjectDetailsTab.name}
            fs={20}
            fw={600}
          />
        </Stack>
        <div
          className="documents"
          dangerouslySetInnerHTML={{
            __html: selectedProjectDetailsTab.description,
          }}
        ></div>
        <Box
          sx={{
            height: "calc(100% - 130px)",
            overflow: "auto",
          }}
        >
          {policyDocs.map((docs, index) => {
            return (
              <DocsAccordion
                key={docs.id}
                documents={docs}
                expanded={expandedPanels.includes(docs.id)}
                handleChange={handleChange(docs.id)}
                handleDrop={(e) => handleDrop(e, index)}
                handleFileChange={(e) => handleFileChange(e, index)}
                deleteDocument={deleteDocument}
                getDocumentList={getDocumentList}
                removeAttachment={(inx) => removeAttachment(inx, index)}
                loading={loading}
                setFile={setFile}
                showButton={
                  (docs?.attachments?.length || docs?.document_urls?.length) &&
                  isNotNullOrUndefined(file)
                    ? file.file && index === file.index
                    : false
                }
              />
            );
          })}
        </Box>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          pt={1}
        >
          <AtsRoundedButton
            text="Back"
            buttonVariant="outline"
            buttonHeight={34}
            buttonWidth={79}
            onClick={backItem}
          />

          <AtsRoundedButton
            text="Next"
            buttonHeight={34}
            buttonWidth={113}
            onClick={nextItem}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default PolicyDocuments;

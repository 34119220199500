import { Stack } from "@mui/material";
import dayjs from "dayjs";
import AtsRow from "../../../../components/AtsRow";
import AtsTimePicker from "../../../../components/AtsTimePicker";
import AtsTitleText from "../../../../components/AtsTitleText";
import { COLORS } from "../../../../theme";

const SetBusinessHours = () => {
  return (
    <>
      <Stack gap={"6px"} pb={"12px"}>
        <AtsRow>
          <>
            <AtsTitleText
              text={"Set Business Hours"}
              fs={16}
              fw={600}
              textColor="#000"
            />
          </>
        </AtsRow>
        <AtsTitleText
          text={"Specify your daily working hours."}
          fs={14}
          fw={500}
          textColor={COLORS.DARK_GRAY}
        />
      </Stack>
      <AtsRow gap={"8px"}>
        <>
          <AtsTimePicker
            fs="14px"
            fw={500}
            value={dayjs(new Date().toString(), "HH:mm")}
          />
          <AtsTimePicker
            fs="14px"
            fw={500}
            value={dayjs(new Date().toString(), "HH:mm")}
          />
        </>
      </AtsRow>
    </>
  );
};

export default SetBusinessHours;

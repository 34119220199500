import { createContext, useContext, useState } from "react";
import { getSubmissionState } from "../redux/slices/submissionSlice";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { MessageTypeEnum } from "../enums/messagingEnums";

type MessagesModuleContextType = {
  selectedMessageType: string;
  setSelectedMessageType: React.Dispatch<React.SetStateAction<string>>;
};

const MessagesModule = createContext<MessagesModuleContextType | null>(null);

export const useMessages = () =>
  useContext(MessagesModule) as MessagesModuleContextType;

export const MessagesProvider = ({ children }: any) => {
  const dispatch = useAppDispatch();
  const { isCandidateDetailsDrawerOpen } = useAppSelector(getSubmissionState);
  const [selectedMessageType, setSelectedMessageType] = useState<string>(
    MessageTypeEnum.All
  );

  return (
    <MessagesModule.Provider
      value={{
        selectedMessageType,
        setSelectedMessageType,
      }}
    >
      {children}
    </MessagesModule.Provider>
  );
};

import { createSlice } from "@reduxjs/toolkit";
import { CompanyInfoResponse } from "../../types/companyTypes";
import {
  ProjectDocsListResponse,
  ProjectDocumentResponse,
} from "../../types/projectTypes";
import { RootState } from "../store";

interface ProjectDetailsState {
  selectedProjectDetailsTab: ProjectDocumentResponse;
  companyInfoState: Partial<CompanyInfoResponse>;
  selectedActiveJob: string;
  companyStateError: any;
  projectDocuments: ProjectDocumentResponse[];
  projectDocsList: ProjectDocsListResponse[];
  currentIndex: number;
  projectTab: ProjectDocumentResponse[];
}

const initialState: ProjectDetailsState = {
  selectedProjectDetailsTab: {
    name: "Company",
    id: "Company",
    description:
      "Complete all fields related to company details, position specifics, and contact information.",
    parent_id: "",
  },
  companyInfoState: {},
  companyStateError: {},
  selectedActiveJob: "",
  projectDocuments: [],
  projectDocsList: [],
  projectTab: [],
  currentIndex: 0,
};

const projectDetailsSlice = createSlice({
  name: "projectDetails",
  initialState,
  reducers: {
    setSelectedProjectDetailsTab: (state: ProjectDetailsState, action) => {
      const { payload } = action;
      state.selectedProjectDetailsTab = payload;
    },
    setCompanyInfoState: (state: ProjectDetailsState, action) => {
      const { payload } = action;
      state.companyInfoState = payload;
    },
    setSelectedActiveJob: (state: ProjectDetailsState, action) => {
      const { payload } = action;
      state.selectedActiveJob = payload;
    },
    setCompanyStateError: (state: ProjectDetailsState, action) => {
      const { payload } = action;
      state.companyStateError = payload;
    },
    setProjectDocuments: (state: ProjectDetailsState, action) => {
      const { payload } = action;
      state.projectDocuments = payload;
    },
    setProjectDocsList: (state: ProjectDetailsState, action) => {
      const { payload } = action;
      state.projectDocsList = payload;
    },
    setCurrentIndex: (state: ProjectDetailsState, action) => {
      const { payload } = action;
      state.currentIndex = payload;
    },
    setProjectTab: (state: ProjectDetailsState, action) => {
      const { payload } = action;
      state.projectTab = payload;
    },
    setInitialProjectDetailsState: () => initialState,
  },
});

export const {
  setInitialProjectDetailsState,
  setSelectedProjectDetailsTab,
  setCompanyInfoState,
  setSelectedActiveJob,
  setCompanyStateError,
  setProjectDocuments,
  setProjectDocsList,
  setCurrentIndex,
  setProjectTab,
} = projectDetailsSlice.actions;
export const getProjectDetailsState = (state: RootState) =>
  state.projectDetails;
export default projectDetailsSlice.reducer;

import React, { useEffect, useState } from "react";

import { Checkbox, Divider, Stack } from "@mui/material";

import {
  AccountCircle,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import AtsDataTable from "../../../../../../../components/AtsDataTable";
import AtsCheckbox from "../../../../../../../components/AtsCheckbox";
import AtsRoundedButton from "../../../../../../../components/AtsRoundedButton";
import AtsTitleText from "../../../../../../../components/AtsTitleText";
import AtsCustomButton from "../../../../../../../components/AtsCustomButton";
import AtsIcon from "../../../../../../../components/AtsIcon";
import { BORDERS, COLORS } from "../../../../../../../theme";
import { FilterIcon } from "../../../../../../../assets/icons/Icons";
import { useAppDispatch } from "../../../../../../../redux/store";
import { setIsContractorDetailsDrawerOpen } from "../../../../../../../redux/slices/contractorSlice";

interface IColumnProps {
  key: string;
  header: string;
  width: string;
  minWidth: string;
}
interface UserProps {
  id: string;
  teamMember: string;
  teamManager: string;
  time: string;
}

const pendingUser: UserProps[] = [
  {
    id: "1",
    teamMember: "Alex Novak",
    teamManager: "Chris McGrath",
    time: "45.00 hrs",
  },
  {
    id: "2",
    teamMember: "Jason Antonio",
    teamManager: "Jason Antonio",
    time: "45.00 hrs",
  },
  {
    id: "3",
    teamMember: "Jason Antonio",
    teamManager: "Jason Antonio",
    time: "45.00 hrs",
  },
  {
    id: "4",
    teamMember: "Jason Antonio",
    teamManager: "Jason Antonio",
    time: "45.00 hrs",
  },
  {
    id: "5",
    teamMember: "Jason Antonio",
    teamManager: "Jason Antonio",
    time: "45.00 hrs",
  },
  {
    id: "6",
    teamMember: "Jason Antonio",
    teamManager: "Jason Antonio",
    time: "45.00 hrs",
  },
  {
    id: "7",
    teamMember: "Jason Antonio",
    teamManager: "Jason Antonio",
    time: "45.00 hrs",
  },
];

const LEAD_COLUMN: IColumnProps[] = [
  {
    key: "id",
    header: "User",
    width: "300px",
    minWidth: "300px",
  },
  {
    key: "teamMember",
    header: "Team Memeber",
    width: "auto",
    minWidth: "200px",
  },

  {
    key: "teamManager",
    header: "Team Manager",
    width: "300px",
    minWidth: "300px",
  },
  {
    key: "time",
    header: "Time",
    width: "100px",
    minWidth: "100px",
  },

  {
    key: "action",
    header: "",
    width: "80px",
    minWidth: "80px",
  },
];

const cellStyles = {
  padding: "9px 10px",
};
const firstCellStyles = {
  padding: "9px 0px",
};

const PendingTimeSheet = () => {
  const dispatch = useAppDispatch();
  // const {isCandidateDetailsDrawerOpen} = useAppSelector(getSubmissionState)
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);

  useEffect(() => {
    setSelectAll(selectedUsers.length === pendingUser.length);
  }, [selectedUsers]);

  const handleUserSelect = (id: string) => {
    setSelectedUsers((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((userId) => userId !== id)
        : [...prevSelected, id]
    );
  };

  const handleSelectAllUser = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setSelectedUsers(newSelectAll ? pendingUser.map((user) => user.id) : []);
  };

  const openContractDetailsDrawer = (contractor: UserProps) => {
    dispatch(setIsContractorDetailsDrawerOpen(true));
  };
  const customizeRow = (column: IColumnProps, contractor: UserProps) => {
    switch (column.key) {
      case "id":
        return (
          <td style={firstCellStyles} key={column.key}>
            <Stack direction={"row"} alignItems={"center"} gap={0.3}>
              <AtsCheckbox
                checked={selectedUsers.includes(contractor.id)}
                onChange={() => handleUserSelect(contractor.id)}
                p={0}
              />
            </Stack>
          </td>
        );
      case "teamMember":
      case "teamManager":
      case "time":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} gap={1}>
              <AtsTitleText
                fs={14}
                fw={400}
                textColor={COLORS.BLACK}
                text={contractor[column.key]}
              />
            </Stack>
          </td>
        );

      case "action":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} alignItems={"center"} gap={1.5}>
              <AtsCustomButton
                px={0.6}
                onClick={() => openContractDetailsDrawer(contractor)}
              >
                <Stack direction={"row"} alignItems={"center"} gap={1}>
                  <AtsIcon icon="icon-eye" height="16px" color={COLORS.BLACK} />
                </Stack>
              </AtsCustomButton>
            </Stack>
          </td>
        );
      default:
        return null;
    }
  };

  const renderRowComponent = () => {
    const leadsData = pendingUser.map((value, index: number) => {
      return (
        <React.Fragment key={index}>
          <tr
            style={{
              borderBottom: BORDERS.GRAY,
              minHeight: "44px",
              margin: "0px 8px",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            {LEAD_COLUMN.map((val: IColumnProps) => customizeRow(val, value))}
          </tr>
        </React.Fragment>
      );
    });
    return leadsData;
  };

  const headerComponent = () => {
    const isIndeterminate =
      selectedUsers.length > 0 && selectedUsers.length < pendingUser.length;
    const headers = LEAD_COLUMN.map((column: IColumnProps, index: number) => (
      <>
        <th
          className="customTH"
          style={{
            width: index === 0 ? "50px" : column.width,
            minWidth: index === 0 ? "50px" : column.minWidth,
            padding: index === 0 ? "15px 0px" : "15px 10px",
            textAlign: "start",
          }}
        >
          {index === 0 ? (
            <AtsCheckbox
              checked={selectAll}
              indeterminate={isIndeterminate}
              onChange={handleSelectAllUser}
              p={0}
            />
          ) : (
            <Stack direction={"row"} alignItems={"center"} gap={2}>
              <AtsCustomButton px={0.5}>
                <Stack direction={"row"} alignItems={"center"} gap={1}>
                  <AtsTitleText
                    text={column.header}
                    fs={14}
                    fw={600}
                    textColor="#4f4f4f"
                    isCursor
                  />
                  {column.header.length > 0 && <FilterIcon />}
                </Stack>
              </AtsCustomButton>
              {column.header.length > 0 && (
                <Stack>
                  <KeyboardArrowUp
                    sx={{
                      color: "#4f4f4f",
                      fontSize: 16,
                      mb: -0.4,
                      cursor: "pointer",
                    }}
                  />
                  <KeyboardArrowDown
                    sx={{
                      color: "#4f4f4f",
                      fontSize: 16,
                      mt: -0.5,
                      cursor: "pointer",
                    }}
                  />
                </Stack>
              )}
            </Stack>
          )}
        </th>
      </>
    ));
    return headers;
  };
  return (
    <>
      <AtsDataTable
        data={pendingUser}
        theadComponent={headerComponent}
        rowComponent={renderRowComponent}
        loading={false}
      />
    </>
  );
};

export default PendingTimeSheet;

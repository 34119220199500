import { Stack, Tab, Tabs } from "@mui/material";
import React, { useMemo, useState } from "react";
import { COLORS } from "../../../../../../../theme";
import { ContractorTabsEnum } from "../../../../../../../types/contractorTypes";
import TimesheetTab from "../Tabs/Timesheet/TimesheetTab";
import ResponsibilitiesTab from "../Tabs/ResponsibilitiesTab";
import ContractsTab from "../Tabs/ContractsTab";
import AssignedTasksTab from "../Tabs/AssignedTasksTab";
import OnboardingTab from "../Tabs/OnboardingTab";
import { useAppDispatch } from "../../../../../../../redux/store";
import { setInitialContractorTimeSheetState } from "../../../../../../../redux/slices/contractorTimesheetSlice";

const ContractorDetailBottomSection = () => {
  const dispatch = useAppDispatch();
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const timesheetTab = useMemo(() => <TimesheetTab />, []);
  const responsibilitiesTab = useMemo(() => <ResponsibilitiesTab />, []);
  const contractsTab = useMemo(() => <ContractsTab />, []);
  const assignedTasksTab = useMemo(() => <AssignedTasksTab />, []);
  const onboardingTab = useMemo(() => <OnboardingTab />, []);
  return (
    <>
      <Stack
        sx={{
          height: "calc(100% - 260px)",
          p: "30px",
        }}
      >
        <Stack direction={"row"} height={"100%"} gap={"50px"}>
          <Stack
            sx={{
              minWidth: "170px",
              backgroundColor: "#f5f5f5",
              borderRadius: "10px",
              py: "14px",
              height: "fit-content",
            }}
          >
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={selectedTab}
              onChange={(e, newValue) => {
                setSelectedTab(newValue);
                dispatch(setInitialContractorTimeSheetState());
              }}
              aria-label="Vertical tabs example"
              sx={{
                ".MuiTab-root": {
                  textAlign: "left",
                  alignItems: "flex-start",
                  pl: 3,
                  textTransform: "capitalize",
                  minHeight: 40,
                },
                ".Mui-selected": {
                  color: "#3366cc !important",
                  fontWeight: 600,
                },
                ".MuiTabs-indicator": {
                  left: 0,
                  height: "26px !important",
                  width: "4px",
                  backgroundColor: COLORS.PRIMARY,
                  borderRadius: "10px",
                  marginTop: "8px",
                },
              }}
              TabIndicatorProps={{
                sx: {
                  left: 0,
                  width: "5px",
                },
              }}
            >
              {Object.keys(ContractorTabsEnum).map((tab) => (
                <Tab key={tab} label={ContractorTabsEnum[tab]} />
              ))}
            </Tabs>
          </Stack>
          <Stack
            style={{
              overflow: "auto",
              height: "100%",
              flex: 1,
            }}
          >
            {selectedTab === 0
              ? timesheetTab
              : selectedTab === 1
              ? responsibilitiesTab
              : selectedTab === 2
              ? contractsTab
              : selectedTab === 3
              ? assignedTasksTab
              : selectedTab === 4
              ? onboardingTab
              : ""}
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default ContractorDetailBottomSection;

import axios from "axios";
import { PaginatedResponse } from "../types/commonType";
import {
  CandidateDetailsProps,
  CandidateRattingListParams,
  CandidateRattingReviewListResponse,
  CandidateSkillsMatchParams,
  CandidateSkillsMatchResponse,
  GetCandidateDetialsParamsRequest,
  GetSubmissionParamsRequest,
  GetSubmissionResponse,
  LogoutParamRequest,
  RatingSummary,
  Skill,
  UpdateRateOptionRequest,
} from "../types/submissionTypes";

const Endpoint = {
  BASE: "/ats/api/v1/",
  SUBMISSIONS: "job-submissions",
  LOGOUT: "user/logout?_format=json",
  CANDIDAET_DETAILS_BY_ID: "candidate/get-by-id",
  CANDIDAET_SKILLS_MATCH: "candidate-skills-match",
  CANDIDATE_RATTING: "candidate-reviews",
  CANDIDATE_RATTING_SUMMARY: "candidate-reviews-summary",
  RATE_OPTION: "rate-options",
  UPDATE_RATE_OPTION: "candidate-rate/create-update",
};

const SUBMISSIONS_URL = Endpoint.BASE + Endpoint.SUBMISSIONS;
const CANDIDATE_RATTING_URL = Endpoint.BASE + Endpoint.CANDIDATE_RATTING;
const RATE_OPTION_URL = Endpoint.BASE + Endpoint.RATE_OPTION;
const UPDATE_RATE_OPTION_URL = Endpoint.BASE + Endpoint.UPDATE_RATE_OPTION;
const CANDIDATE_RATTING_SUMMARY_URL =
  Endpoint.BASE + Endpoint.CANDIDATE_RATTING_SUMMARY;
const CANDIDAET_SKILLS_MATCH_URL =
  Endpoint.BASE + Endpoint.CANDIDAET_SKILLS_MATCH;
const CANDIDAET_DETAILS_BY_ID_URL =
  Endpoint.BASE + Endpoint.CANDIDAET_DETAILS_BY_ID;

export class SubmissionService {
  static async getSubmissionByJobId(
    params: Partial<GetSubmissionParamsRequest>
  ): Promise<PaginatedResponse<GetSubmissionResponse>> {
    const endpoint = SUBMISSIONS_URL;
    const response = await axios.get(endpoint, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
  static async getCandidateDetailsById(
    params: Partial<GetCandidateDetialsParamsRequest>
  ): Promise<PaginatedResponse<CandidateDetailsProps>> {
    const endpoint = CANDIDAET_DETAILS_BY_ID_URL;
    const response = await axios.get(endpoint, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
  static async getCandidateSkillsMatch(
    params: Partial<CandidateSkillsMatchParams>
  ): Promise<CandidateSkillsMatchResponse> {
    const endpoint = CANDIDAET_SKILLS_MATCH_URL;
    const response = await axios.get(endpoint, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async getCandidateRattingList(
    params: Partial<CandidateRattingListParams>
  ): Promise<PaginatedResponse<CandidateRattingReviewListResponse>> {
    const endpoint = CANDIDATE_RATTING_URL;
    const response = await axios.get(endpoint, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async getCandidateRattingSummary(
    params: Partial<CandidateSkillsMatchParams>
  ): Promise<RatingSummary> {
    const endpoint = CANDIDATE_RATTING_SUMMARY_URL;
    const response = await axios.get(endpoint, {
      params,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async getRateOptions(): Promise<PaginatedResponse<Skill>> {
    const endpoint = RATE_OPTION_URL;
    const response = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async updateRateOptions(request: UpdateRateOptionRequest) {
    const endpoint = UPDATE_RATE_OPTION_URL;
    const response = await axios.post(endpoint, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }

  static async logOut(params: Partial<LogoutParamRequest>) {
    const endpoint = Endpoint.LOGOUT;
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const response = await axios.get(endpoint, {
      params,
      headers: {
        "Content-type": "application/json",
        "X-CSRF-Token": `${userInfo.csrf_token}`,
      },
    });
    return response.data;
  }
}

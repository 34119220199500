export enum ContractorTabsEnum {
  Timesheet = "Timesheet",
  Responsibilities = "Responsibilities",
  Contracts = "Contracts",
  AssignedTasks = "Assigned Tasks",
  Onboarding = "Onboarding",
}

export enum DisputeTabsEnum {
  DisputeEntries = "Dispute entries",
  EvidenceDetails = "Evidence details",
}
export enum DisputeResolutionTabsEnum {
  Disputed = "Disputed",
  UnderReview = "Under review",
  Resolved = "Resolved",
}

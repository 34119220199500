import React from "react";
import AtsTitleText from "../../../../../../../../components/AtsTitleText";
import AtsRow from "../../../../../../../../components/AtsRow";
import { Box } from "@mui/material";
import { COLORS } from "../../../../../../../../theme";

const DisputeUpdate = () => {
  return (
    <>
      <AtsTitleText
        text={"Dispute resolution"}
        fs={18}
        fw={600}
        textColor={"#000"}
      />
      <AtsRow gap={"89px"}>
        <>
          <Box
            sx={{
              width: 200,
            }}
          >
            <AtsTitleText
              text={"From Esteemed:"}
              fs={14}
              fw={500}
              textColor={COLORS.DARK_GRAY}
            />
          </Box>
          <Box sx={{}}>
            <AtsTitleText
              text={"The dispute was settled in your favor."}
              fs={14}
              fw={400}
              textColor={"#1c1c1c"}
            />
          </Box>
        </>
      </AtsRow>
      <AtsRow gap={"89px"}>
        <>
          <Box
            sx={{
              width: 200,
            }}
          >
            <AtsTitleText
              text={"Time reduced by contractor:"}
              fs={14}
              fw={500}
              textColor={COLORS.DARK_GRAY}
            />
          </Box>
          <Box sx={{}}>
            <AtsTitleText
              text={"02.45 hrs"}
              fs={14}
              fw={400}
              textColor={"#e63030"}
            />
          </Box>
        </>
      </AtsRow>
    </>
  );
};

export default DisputeUpdate;

import React from "react";
import AtsTitleText from "../../../../../../../../components/AtsTitleText";
import AtsRow from "../../../../../../../../components/AtsRow";
import { Box, Stack } from "@mui/material";
import { BORDERS, COLORS } from "../../../../../../../../theme";

const Evidence = () => {
  return (
    <>
      <AtsTitleText text={"Evidence"} fs={18} fw={600} textColor={"#000"} />
      <Stack direction={"row"} alignItems={"flex-start"} gap={"89px"}>
        <>
          <Box
            sx={{
              width: 200,
            }}
          >
            <AtsTitleText
              text={"Your evidence"}
              fs={14}
              fw={500}
              textColor={COLORS.DARK_GRAY}
            />
          </Box>
          <AtsRow columnGap={"16px"} rowGap={"10px"} flexWrap={"wrap"}>
            <>
              <Stack gap={"9px"}>
                <Box
                  sx={{
                    border: BORDERS.GRAY,
                    borderRadius: "10px",
                    width: 50,
                    height: 50,
                  }}
                ></Box>
                <AtsTitleText
                  text={"c...ge.jpg"}
                  fs={11}
                  fw={400}
                  textColor={COLORS.LIGHT_GRAY}
                />
              </Stack>
              <Stack gap={"9px"}>
                <Box
                  sx={{
                    border: BORDERS.GRAY,
                    borderRadius: "10px",
                    width: 50,
                    height: 50,
                  }}
                ></Box>
                <AtsTitleText
                  text={"c...ge.jpg"}
                  fs={11}
                  fw={400}
                  textColor={COLORS.LIGHT_GRAY}
                />
              </Stack>
            </>
          </AtsRow>
        </>
      </Stack>
      <Stack direction={"row"} alignItems={"flex-start"} gap={"89px"}>
        <>
          <Box
            sx={{
              width: 200,
            }}
          >
            <AtsTitleText
              text={"Contractor’s evidence"}
              fs={14}
              fw={500}
              textColor={COLORS.DARK_GRAY}
            />
          </Box>
          <AtsRow columnGap={"16px"} rowGap={"10px"} flexWrap={"wrap"}>
            <>
              <Stack gap={"9px"}>
                <Box
                  sx={{
                    border: BORDERS.GRAY,
                    borderRadius: "10px",
                    width: 50,
                    height: 50,
                  }}
                ></Box>
                <AtsTitleText
                  text={"c...ge.jpg"}
                  fs={11}
                  fw={400}
                  textColor={COLORS.LIGHT_GRAY}
                />
              </Stack>
              <Stack gap={"9px"}>
                <Box
                  sx={{
                    border: BORDERS.GRAY,
                    borderRadius: "10px",
                    width: 50,
                    height: 50,
                  }}
                ></Box>
                <AtsTitleText
                  text={"c...ge.jpg"}
                  fs={11}
                  fw={400}
                  textColor={COLORS.LIGHT_GRAY}
                />
              </Stack>
              <Stack gap={"9px"}>
                <Box
                  sx={{
                    border: BORDERS.GRAY,
                    borderRadius: "10px",
                    width: 50,
                    height: 50,
                  }}
                ></Box>
                <AtsTitleText
                  text={"c...ge.jpg"}
                  fs={11}
                  fw={400}
                  textColor={COLORS.LIGHT_GRAY}
                />
              </Stack>
              <Stack gap={"9px"}>
                <Box
                  sx={{
                    border: BORDERS.GRAY,
                    borderRadius: "10px",
                    width: 50,
                    height: 50,
                  }}
                ></Box>
                <AtsTitleText
                  text={"c...ge.jpg"}
                  fs={11}
                  fw={400}
                  textColor={COLORS.LIGHT_GRAY}
                />
              </Stack>
            </>
          </AtsRow>
        </>
      </Stack>
    </>
  );
};

export default Evidence;

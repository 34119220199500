import { Box, IconButton, Stack } from "@mui/material";
import AtsIcon from "../../../../components/AtsIcon";
import AtsRow from "../../../../components/AtsRow";
import AtsRowBetween from "../../../../components/AtsRowBetween";
import AtsTitleText from "../../../../components/AtsTitleText";
import { MessageTypeEnum } from "../../../../enums/messagingEnums";
import { useMessages } from "../../../../providers/MessagesProvider";
import { BORDERS } from "../../../../theme";
import JobsSwitch from "../../../Dashboard/PostedJobs/JobsSwitch";
import UserInfo from "./components/UserInfo";

const MessageLeftComponent = () => {
  const { setSelectedMessageType, selectedMessageType } = useMessages();
  return (
    <>
      <Box
        sx={{
          height: "100vh",
          width: 320,
          bgcolor: "white",
          borderRight: BORDERS.GRAY,
        }}
      >
        <Stack
          sx={{
            pr: "15px",
            pl: "25px",
            py: "20px",
            maxHeight: "100vh",
          }}
        >
          <AtsRowBetween pb={"10px"}>
            <>
              <AtsTitleText
                text={"Messaging"}
                textColor="#000"
                fs={24}
                fw={600}
              />
              <AtsRow>
                <>
                  <IconButton
                    sx={{
                      p: 1,
                    }}
                  >
                    <AtsIcon
                      icon="icon-search-normal-1"
                      height="16px"
                      color="#000"
                      fw={600}
                    />
                  </IconButton>
                  <IconButton
                    sx={{
                      p: 1,
                    }}
                  >
                    <AtsIcon
                      icon="icon-add-circle"
                      height="16px"
                      color="#000"
                      fw={600}
                    />
                  </IconButton>
                  <IconButton
                    sx={{
                      p: 1,
                    }}
                  >
                    <AtsIcon
                      icon="icon-setting-4"
                      height="16px"
                      color="#000"
                      fw={600}
                    />
                  </IconButton>
                </>
              </AtsRow>
            </>
          </AtsRowBetween>
          <AtsRow>
            <JobsSwitch
              width="60px"
              height="30px"
              jobs={Object.keys(MessageTypeEnum).map((type) => ({
                title: type,
                type: type,
              }))}
              active={selectedMessageType}
              onChange={(selected) => {
                setSelectedMessageType(selected);
              }}
            />
          </AtsRow>
          <Stack gap={"10px"} py={"25px"}>
            <UserInfo />
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

export default MessageLeftComponent;

import React from "react";
import { Box } from "@mui/material";
import AtsRow from "../../../../../../../../../components/AtsRow";
import AtsCustomButton from "../../../../../../../../../components/AtsCustomButton";
import AtsRoundedButton from "../../../../../../../../../components/AtsRoundedButton";
import AtsTitleText from "../../../../../../../../../components/AtsTitleText";
import { DisputeTabsEnum } from "../../../../../../../../../types/contractorTypes";
import AtsRowBetween from "../../../../../../../../../components/AtsRowBetween";
import { Stack } from "@mui/material";
import { Typography } from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../redux/store";
import {
  getContractorTimesheetState,
  setIsDisputed,
  setSelectedDisputeFlowTab,
  setTimesheetsData,
} from "../../../../../../../../../redux/slices/contractorTimesheetSlice";
import FontUtil from "../../../../../../../../../utils/FontUtil";
import { COLORS } from "../../../../../../../../../theme";
import { QuestionHelpIcon } from "../../../../../../../../../assets/icons/Icons";
import AtsIcon from "../../../../../../../../../components/AtsIcon";
import AtsTextArea from "../../../../../../../../../components/AtsTextArea";

const EvidenceDetails = () => {
  const dispatch = useAppDispatch();
  const { selectedContractorTimesheets, timesheetsData } = useAppSelector(
    getContractorTimesheetState
  );
  const { font14 } = FontUtil();

  const getDisputeData = (id: string) => {
    const isDisputed = selectedContractorTimesheets.some(
      (selected) => selected.id === id
    );
    return isDisputed;
  };

  const onDispute = () => {
    const disputedData = timesheetsData.map((data) => {
      const isDispute = getDisputeData(data.id);
      if (isDispute) {
        return { ...data, isDisputed: true };
      } else {
        return data;
      }
    });

    dispatch(setTimesheetsData(disputedData));
    dispatch(setIsDisputed(true));
  };
  return (
    <>
      <Box
        px={"30px"}
        mt={"30px"}
        sx={{
          height: "calc(100% - 265px)",
        }}
      >
        <Stack
          className="scrollHide"
          sx={{
            height: "100%",
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              pb: "20px",
            }}
          >
            <Typography
              sx={{
                ...font14,
                fontWeight: 400,
                color: "#000",
              }}
            >
              You have selected{" "}
              <span style={{ fontWeight: 600 }}>
                {selectedContractorTimesheets.length} entries{" "}
              </span>{" "}
              to dispute
            </Typography>
          </Box>
          <AtsRowBetween
            sx={{
              minHeight: 43,
              px: "14px",
              bgcolor: "#fff3f3",
              borderRadius: "6px",
              mb: "40px",
            }}
          >
            <>
              <AtsTitleText
                text={"Total time disputed"}
                fs={14}
                fw={600}
                textColor={"#e63030"}
              />
              <AtsTitleText
                text={"10.00 hrs"}
                fs={14}
                fw={600}
                textColor={"#e63030"}
              />
            </>
          </AtsRowBetween>
          <Stack gap={"9px"} mb={"40px"}>
            <AtsRowBetween>
              <>
                <Typography
                  sx={{
                    ...font14,
                    fontWeight: 600,
                    color: COLORS.BLACK,
                  }}
                >
                  Attach your evidence
                  <span
                    style={{
                      color: "red",
                    }}
                  >
                    *
                  </span>
                </Typography>
                <AtsRow gap={"2.5px"}>
                  <>
                    <QuestionHelpIcon />
                    <AtsTitleText
                      text={"help"}
                      fs={12}
                      fw={600}
                      textColor={COLORS.PRIMARY}
                    />
                  </>
                </AtsRow>
              </>
            </AtsRowBetween>

            <AtsTitleText
              text={
                "This will be sent to the contractor and the account manager."
              }
              fs={12}
              fw={400}
              textColor={COLORS.DARK_GRAY}
            />
            <Box
              sx={{
                width: 80,
                height: 80,
                borderRadius: "10px",
                // border: BORDERS.GRAY,
              }}
            >
              <label
                htmlFor="chooseVideo"
                style={{
                  height: "100%",
                  borderRadius: "10px",
                  border: "1px dashed #3366cc",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: "5px",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                // onDrop={handleDrop}
                // onDragOver={handleDragOver}
              >
                <AtsIcon icon="icon-add" height="16px" fs={16} color={"#000"} />

                <AtsTitleText
                  text={"Upload"}
                  fs={14}
                  fw={400}
                  textColor={"#000"}
                />
                <input
                  type="file"
                  name=""
                  accept="image/*"
                  id="chooseVideo"
                  hidden
                  //   ref={fileInputRef}
                  //   onChange={handleFileChange}
                />
              </label>
            </Box>
            <AtsTitleText
              text={
                "supported formats: CSV, PDF, JPG, PNG | Max file size: 10 mb"
              }
              fs={11}
              fw={500}
              textColor={COLORS.DARK_GRAY}
            />
          </Stack>
          <Stack gap={"9px"} mb={"40px"}>
            <Typography
              sx={{
                ...font14,
                fontWeight: 600,
                color: COLORS.BLACK,
              }}
            >
              Attach your evidence
              <span
                style={{
                  color: "red",
                }}
              >
                *
              </span>
            </Typography>

            <AtsTitleText
              text={
                "This will be sent to the contractor and the account manager."
              }
              fs={12}
              fw={400}
              textColor={COLORS.DARK_GRAY}
            />
            <AtsTextArea cols={12} rows={4} className="textArea" />
          </Stack>
        </Stack>
      </Box>
      <AtsRowBetween px={"50px"} pt={"30px"} pb={"50px"}>
        <>
          <AtsCustomButton
            px={0}
            py={0}
            onClick={() =>
              dispatch(
                setSelectedDisputeFlowTab(DisputeTabsEnum.DisputeEntries)
              )
            }
          >
            <AtsTitleText text={"Back"} fw={400} textColor="#050505" />
          </AtsCustomButton>
          <AtsRow gap={"50px"}>
            <>
              <AtsCustomButton px={0} py={0}>
                <AtsTitleText text={"Cancel"} fw={400} textColor="#050505" />
              </AtsCustomButton>
              <AtsRoundedButton
                buttonVariant="redContained"
                text="Dispute"
                fw={400}
                isInitial
                buttonWidth={127}
                buttonHeight={37}
                onClick={() => onDispute()}
              />
            </>
          </AtsRow>
        </>
      </AtsRowBetween>
    </>
  );
};

export default EvidenceDetails;

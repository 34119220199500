import React, { useCallback, useState } from "react";

import { AnimatePresence, motion } from "framer-motion";
import { ContractorTimesheetProps } from "../../../../../../../redux/slices/contractorTimesheetSlice";
import FontUtil from "../../../../../../../utils/FontUtil";
import { Box, Stack, Typography } from "@mui/material";
import AtsIcon from "../../../../../../../components/AtsIcon";
import { BORDERS, COLORS } from "../../../../../../../theme";
import AtsRow from "../../../../../../../components/AtsRow";
import AtsTitleText from "../../../../../../../components/AtsTitleText";
import AtsRowBetween from "../../../../../../../components/AtsRowBetween";

const slideVariants = {
  enter: (direction) => ({
    x: direction > 0 ? 1000 : -1000,
    opacity: 0,
  }),
  center: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.3,
      ease: "easeOut",
    },
  },
  exit: (direction) => ({
    x: direction > 0 ? -1000 : 1000,
    opacity: 0,
    transition: {
      duration: 0.3,
      ease: "easeIn",
    },
  }),
};

interface IDisputedEntrieCardProps {
  disputedEntries: ContractorTimesheetProps[];
}

const DisputedEntriesCard = ({ disputedEntries }: IDisputedEntrieCardProps) => {
  const { font14, font12 } = FontUtil();
  const [selectedTimesheet, setSelectedTimesheet] = useState<number>(0);
  const [direction, setDirection] = React.useState(0);

  const disputeData = useCallback(() => {
    if (!disputedEntries[selectedTimesheet]) return [];

    const data = [
      {
        title: "Date",
        value: disputedEntries[selectedTimesheet].date,
      },
      {
        title: "Task",
        value: disputedEntries[selectedTimesheet].task,
      },
      {
        title: "Description of work",
        value: disputedEntries[selectedTimesheet].DescriptionOfWork,
      },
      {
        title: "Start time - end time",
        value: disputedEntries[selectedTimesheet].startEndTime,
      },
    ];

    return data;
  }, [disputedEntries, selectedTimesheet]);

  const prevItem = () => {
    if (selectedTimesheet !== 0) {
      setSelectedTimesheet(selectedTimesheet - 1);
    }
  };
  const lastIndex = disputedEntries.length - 1;
  const nextItem = () => {
    if (lastIndex !== selectedTimesheet) {
      setSelectedTimesheet(selectedTimesheet + 1);
    }
  };

  const handleNext = () => {
    setDirection(1);
    nextItem();
  };

  const handlePrev = () => {
    setDirection(-1);
    prevItem();
  };

  return (
    <>
      <Box
        sx={{
          mx: "30px",

          position: "relative",
        }}
      >
        {disputedEntries.length > 1 && (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: -30,
              cursor: "pointer",
              ...(selectedTimesheet === 0 && {
                opacity: "0.5",
                cursor: "default",
              }),
            }}
          >
            <Box px={0} py={0} onClick={handlePrev}>
              <AtsIcon icon="icon-arrow-left-2" height="25px" fs={25} />
            </Box>
          </Box>
        )}
        <AnimatePresence initial={false} custom={direction} mode="wait">
          <motion.div
            key={selectedTimesheet}
            custom={direction}
            variants={slideVariants}
            initial="enter"
            animate="center"
            exit="exit"
          >
            <Box
              sx={{
                px: "54px",
                py: "16px",
                bgcolor: "#f5f6f8",
                borderRadius: "6px",
              }}
            >
              <Stack
                textAlign={"center"}
                sx={{
                  pb: "13px",
                }}
              >
                <Typography
                  sx={{
                    ...font12,
                    fontWeight: 400,
                    color: "#000",
                  }}
                >
                  <span style={{ fontWeight: 600 }}>
                    {" "}
                    {selectedTimesheet + 1}{" "}
                  </span>
                  of{" "}
                  <span style={{ fontWeight: 600 }}>
                    {disputedEntries.length}
                  </span>{" "}
                  dispute entries
                </Typography>
              </Stack>
              <Stack
                gap={"8px"}
                sx={{
                  pt: "14px",
                  pb: "13px",
                  borderTop: BORDERS.GRAY,
                  borderBottom: BORDERS.GRAY,
                }}
              >
                {disputeData().map((data, index) => (
                  <AtsRow key={index}>
                    <>
                      <Stack width={"50%"}>
                        <AtsTitleText
                          text={data.title}
                          fs={12}
                          fw={400}
                          textColor={COLORS.DARK_GRAY}
                        />
                      </Stack>
                      <Stack width={"50%"} textAlign={"end"}>
                        <AtsTitleText
                          text={data.value}
                          fs={12}
                          fw={400}
                          textColor={COLORS.DARK_GRAY}
                        />
                      </Stack>
                    </>
                  </AtsRow>
                ))}
              </Stack>
              <AtsRowBetween
                sx={{
                  pt: "14px",
                  pb: "13px",
                  borderBottom: BORDERS.GRAY,
                }}
              >
                <>
                  <AtsTitleText
                    text={"Time"}
                    fs={12}
                    fw={600}
                    textColor={"#000"}
                  />
                  <AtsTitleText
                    text={"10.00 hrs"}
                    fs={12}
                    fw={600}
                    textColor={"#000"}
                  />
                </>
              </AtsRowBetween>
              <AtsRowBetween
                sx={{
                  pt: "14px",
                }}
              >
                <>
                  <AtsTitleText
                    text={"Time disputed in this entry"}
                    fs={14}
                    fw={600}
                  />
                  <AtsRow gap={"6px"}>
                    <>
                      <AtsRowBetween
                        gap={"5px"}
                        sx={{
                          px: "10px",
                        }}
                      >
                        <>
                          <AtsTitleText text={"02"} fs={14} fw={600} />
                          <AtsTitleText
                            text={"hrs"}
                            fs={14}
                            fw={400}
                            textColor={COLORS.PRIMARY}
                          />
                        </>
                      </AtsRowBetween>
                      <AtsRowBetween
                        gap={"5px"}
                        sx={{
                          px: "10px",
                        }}
                      >
                        <>
                          <AtsTitleText text={"45"} fs={14} fw={600} />
                          <AtsTitleText
                            text={"mins"}
                            fs={14}
                            fw={400}
                            textColor={COLORS.PRIMARY}
                          />
                        </>
                      </AtsRowBetween>
                    </>
                  </AtsRow>
                </>
              </AtsRowBetween>
            </Box>
          </motion.div>
        </AnimatePresence>

        {disputedEntries.length > 1 && (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              right: -30,
              cursor: "pointer",
              ...(lastIndex === selectedTimesheet && {
                opacity: "0.5",
                cursor: "default",
              }),
            }}
          >
            <Box px={0} py={0} onClick={handleNext}>
              <AtsIcon icon="icon-arrow-right-3" height="25px" fs={25} />
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default DisputedEntriesCard;

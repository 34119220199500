import axios from "axios";
import {
  AcceptInviteRequest,
  ClientUser,
  SendInviteUserRequest,
  UserImageResponse,
  UserInformation,
} from "../types/userManagementTypes";
import { PaginatedResponse, TitleIdObjResponse } from "../types/commonType";

const Endpoint = {
  BASE: "ats/api/v1/",
  CLIENT_ROLES: "client-roles?_format=json",
  INVITED_USERS: "invited-users",
  INVITE_USERS: "invite",
  USERINFO: "userinfo?_format=json",
  ACCEPT_INVITE: "accept-invite",
  GET_USER_IMAGE: "users-image",
};

const CLIENT_ROLES_URL = Endpoint.BASE + Endpoint.CLIENT_ROLES;
const INVITED_USERS_URL = Endpoint.BASE + Endpoint.INVITED_USERS;
const INVITE_USERS_URL = Endpoint.BASE + Endpoint.INVITE_USERS;
const USERINFO_URL = Endpoint.BASE + Endpoint.USERINFO;
const ACCEPT_INVITE_URL = Endpoint.BASE + Endpoint.ACCEPT_INVITE;
const GET_USER_IMAGE_URL = Endpoint.BASE + Endpoint.GET_USER_IMAGE;

const headers = {
  Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
};

export class UserManagementServices {
  static async getUserInfo(access_token: string): Promise<UserInformation> {
    const endpoint = USERINFO_URL;
    const response = await axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return response.data;
  }
  static async getClientRoles(): Promise<
    PaginatedResponse<TitleIdObjResponse>
  > {
    const endpoint = CLIENT_ROLES_URL;
    const response = await axios.get(endpoint, {
      headers,
    });
    return response.data;
  }

  static async getInvitedUsers(): Promise<PaginatedResponse<ClientUser>> {
    const endpoint = INVITED_USERS_URL;
    const response = await axios.get(endpoint, {
      headers,
    });
    return response.data;
  }
  static async sendInviteUsers(request: SendInviteUserRequest) {
    const endpoint = INVITE_USERS_URL;
    const response = await axios.post(endpoint, request, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    return response.data;
  }
  static async acceptInvite(request: AcceptInviteRequest) {
    const endpoint = ACCEPT_INVITE_URL;
    const response = await axios.post(endpoint, request, {
      headers: {
        Authorization: `cookie`,
      },
    });
    return response.data;
  }

  static async getUserImage(params: {
    email: string;
  }): Promise<PaginatedResponse<UserImageResponse>> {
    const endpoint = GET_USER_IMAGE_URL;
    const response = await axios.get(endpoint, {
      params,
      headers: {
        Authorization: `cookie`,
      },
    });
    return response.data;
  }
}

import { Box, Stack, Typography } from "@mui/material";
import AtsCustomButton from "../../../../../../../../../components/AtsCustomButton";
import AtsRoundedButton from "../../../../../../../../../components/AtsRoundedButton";
import AtsRow from "../../../../../../../../../components/AtsRow";
import AtsRowBetween from "../../../../../../../../../components/AtsRowBetween";
import AtsTitleText from "../../../../../../../../../components/AtsTitleText";
import {
  getContractorTimesheetState,
  setSelectedDisputeFlowTab,
} from "../../../../../../../../../redux/slices/contractorTimesheetSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../redux/store";
import { DisputeTabsEnum } from "../../../../../../../../../types/contractorTypes";
import FontUtil from "../../../../../../../../../utils/FontUtil";
import DisputedEntriesCard from "./DisputedEntriesCard";

const DisputeEntries = () => {
  const dispatch = useAppDispatch();
  const { selectedContractorTimesheets } = useAppSelector(
    getContractorTimesheetState
  );
  const { font14 } = FontUtil();

  return (
    <>
      <Box
        px={"20px"}
        mt={"30px"}
        sx={{
          height: "calc(100% - 265px)",
        }}
      >
        <Stack
          sx={{
            height: "100%",
            overflow: "auto",
          }}
          gap={"20px"}
        >
          <Stack textAlign={"center"}>
            <Typography
              sx={{
                ...font14,
                fontWeight: 400,
                color: "#000",
              }}
            >
              You have selected{" "}
              <span style={{ fontWeight: 600 }}>
                {selectedContractorTimesheets.length} entries{" "}
              </span>{" "}
              to dispute
            </Typography>
          </Stack>

          <DisputedEntriesCard disputedEntries={selectedContractorTimesheets} />
          <AtsRowBetween
            sx={{
              minHeight: 43,
              px: "14px",
              mx: "30px",
              bgcolor: "#fff3f3",
              borderRadius: "6px",
            }}
          >
            <>
              <AtsTitleText
                text={"Total time disputed"}
                fs={14}
                fw={600}
                textColor={"#e63030"}
              />
              <AtsTitleText
                text={"10.00 hrs"}
                fs={14}
                fw={600}
                textColor={"#e63030"}
              />
            </>
          </AtsRowBetween>
        </Stack>
      </Box>
      <AtsRow
        px={"50px"}
        pt={"30px"}
        pb={"50px"}
        gap={"50px"}
        justifyContent={"flex-end"}
      >
        <>
          <AtsCustomButton px={0} py={0}>
            <AtsTitleText text={"Cancel"} fw={400} textColor="#050505" />
          </AtsCustomButton>
          <AtsRoundedButton
            text="Next attach your evidence"
            fw={400}
            isInitial
            buttonWidth={220}
            buttonHeight={37}
            onClick={() =>
              dispatch(
                setSelectedDisputeFlowTab(DisputeTabsEnum.EvidenceDetails)
              )
            }
          />
        </>
      </AtsRow>
    </>
  );
};

export default DisputeEntries;

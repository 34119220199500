import { Box, Drawer, IconButton } from "@mui/material";
import { useDisputeResolution } from "../../../../../../../providers/DisputeResolutionProvider";
import { Close } from "@mui/icons-material";
import AtsTitleText from "../../../../../../../components/AtsTitleText";
import { Stack } from "@mui/material";
import { BG_COLORS, BORDERS, COLORS } from "../../../../../../../theme";
import TimelineTabs from "./DisputeResolution/TimelineTabs";
import DisputeUpdate from "./DisputeResolution/DisputeUpdate";
import Summary from "./DisputeResolution/Summary";
import AtsRowBetween from "../../../../../../../components/AtsRowBetween";
import AtsRoundedButton from "../../../../../../../components/AtsRoundedButton";
import Evidence from "./DisputeResolution/Evidence";
import AtsCustomButton from "../../../../../../../components/AtsCustomButton";
import AtsRow from "../../../../../../../components/AtsRow";
import AtsIcon from "../../../../../../../components/AtsIcon";

const DisputeResolutionDrawer = () => {
  const { openCloseDisputedResolutionDrawer, openDrawer, show } =
    useDisputeResolution();
  return (
    <>
      <Drawer
        sx={{}}
        PaperProps={{
          sx: {
            borderTopLeftRadius: "20px",
            borderBottomLeftRadius: "20px",
            width: "670px",
            overflow: "hidden",
          },
        }}
        anchor="right"
        open={openDrawer}
        onClose={openCloseDisputedResolutionDrawer}
      >
        <Box
          sx={{
            bgcolor: "background.paper",
            border: "0px solid #000",
            boxShadow: 24,
            outline: "0",
            borderTopLeftRadius: 3,
            borderBottomLeftRadius: 3,
            pb: 2,
            height: "100%",
          }}
        >
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{ borderBottom: BORDERS.GRAY, px: "30px", height: 65 }}
          >
            <AtsTitleText
              text={"Dispute resolution"}
              fs={18}
              fw={600}
              textColor={"#000"}
            />
            <IconButton
              color="inherit"
              onClick={openCloseDisputedResolutionDrawer}
            >
              <Close
                sx={{
                  fontSize: "25px",
                }}
              />
            </IconButton>
          </Stack>
          <TimelineTabs />
          <Box
            sx={{
              px: "30px",
              my: "30px",
              height: `calc(100% - ${show ? "272px" : "256px"})`,
              overflow: "auto",
            }}
          >
            <Stack
              gap={"16px"}
              sx={{
                pb: "29px",
                borderBottom: BORDERS.GRAY,
              }}
            >
              <DisputeUpdate />
            </Stack>
            <Stack
              gap={"16px"}
              sx={{
                py: "29px",
                borderBottom: BORDERS.GRAY,
              }}
            >
              <Summary />
            </Stack>
            <Stack
              gap={"16px"}
              sx={{
                py: "29px",
                borderBottom: BORDERS.GRAY,
              }}
            >
              <Evidence />
            </Stack>
          </Box>
          <AtsRowBetween
            sx={{
              py: "10px",
              px: "20px",
              boxShadow: "0 -2px 6px 0 rgba(0, 0, 0, 0.12)",
              bgcolor: BG_COLORS.GRAY,
            }}
          >
            <>
              <AtsRoundedButton
                text="Withdraw"
                buttonVariant="outline"
                buttonWidth={124}
              />
              <AtsCustomButton px={0} py={0}>
                <AtsRow gap={"5px"}>
                  <>
                    <AtsIcon icon="icon-call" height="16px" fs={16} fw={600} />
                    <AtsTitleText
                      text={"Schedule a call"}
                      fs={14}
                      fw={400}
                      textColor={"#000"}
                    />
                  </>
                </AtsRow>
              </AtsCustomButton>
            </>
          </AtsRowBetween>
        </Box>
      </Drawer>
    </>
  );
};

export default DisputeResolutionDrawer;

import React, { useEffect, useState } from "react";
import AtsRoundedButton from "../../../../components/AtsRoundedButton";
import AtsRow from "../../../../components/AtsRow";
import { Box, Collapse, IconButton, Skeleton } from "@mui/material";
import { BORDERS, COLORS } from "../../../../theme";
import { Stack } from "@mui/material";
import AtsTitleText from "../../../../components/AtsTitleText";
import AtsSelect from "../../../../components/AtsSelect";
import AtsAntSwitch from "../../../../components/AtsAntSwitch";
import AtsTimePicker from "../../../../components/AtsTimePicker";
import { Add, Close, ContentCopy } from "@mui/icons-material";
import { CalendarServices } from "../../../../Api/calendarServices";
import {
  initialAvailability,
  useCalendarAvailability,
} from "../../../../providers/CalendarAvailabilityProvider";
import dayjs from "dayjs";
import { DayAvailability } from "../../../../types/calendarTypes";
import AtsCheckbox from "../../../../components/AtsCheckbox";
import { useClickOutSide } from "../../../../utils/common";
import AtsRowBetween from "../../../../components/AtsRowBetween";

const timezone = [{ label: "India Standerd Time", value: "INDIAN" }];

const WeeklyAvailability = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [selectedAvailability, setSelectedAvailability] = useState<string[]>(
    []
  );
  const [select, setSelect] = useState<string>("");

  const {
    availability,
    selectedTimezone,
    setSelectedTimezone,
    setAvailability,
  } = useCalendarAvailability();

  const fetchAvailability = async () => {
    setLoading(true);
    try {
      const response = await CalendarServices.getAvailability();
      if (response.availability.length) {
        const filteredAvailability = initialAvailability.map((availability) => {
          const matchingResDay = response.availability.find(
            (resDay) => resDay.day === availability.day
          );
          if (matchingResDay) {
            return {
              ...availability,
              is_unavailable: matchingResDay.is_unavailable,
              slots: matchingResDay.slots,
            };
          }

          return availability;
        });
        setAvailability(filteredAvailability);
      } else {
        setAvailability(initialAvailability);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAvailability();
  }, []);

  const handleToggleAvailability = (check, dayIndex) => {
    if (check) {
      setAvailability((prev) =>
        prev.map((day, index) =>
          index === dayIndex
            ? {
                ...day,
                slots: day.slots.length
                  ? day.slots
                  : [{ start: "09:00", end: "09:30" }],
                is_unavailable: check ? 0 : 1,
              }
            : day
        )
      );
    } else {
      setAvailability((prev) =>
        prev.map((day, index) =>
          index === dayIndex ? { ...day, is_unavailable: 1, slots: [] } : day
        )
      );
    }
  };

  const handleTimeChange = (dayIndex, slotIndex, type, newTime) => {
    setAvailability((prev) =>
      prev.map((day, index) =>
        index === dayIndex
          ? {
              ...day,
              slots: day.slots.map((slot, sIndex) =>
                sIndex === slotIndex
                  ? { ...slot, [type]: newTime.format("HH:mm") }
                  : slot
              ),
            }
          : day
      )
    );
  };

  const addHalfHour = (time) => {
    let [hours, minutes] = time.split(":").map(Number);

    let totalMinutes = hours * 60 + minutes;

    totalMinutes += 30;

    hours = Math.floor(totalMinutes / 60) % 24;
    minutes = totalMinutes % 60;
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}`;
  };
  const handleAddSlot = (dayIndex, slotIndex) => {
    setAvailability((prev) => {
      const currentAvailability = prev[dayIndex];
      const preSlot =
        currentAvailability.slots[currentAvailability.slots.length - 1];

      return prev.map((day, index) =>
        index === dayIndex
          ? {
              ...day,
              slots: [
                ...day.slots,
                { start: preSlot.end, end: addHalfHour(preSlot.end) },
              ],
            }
          : day
      );
    });
  };

  const handleRemoveSlot = (dayIndex, slotIndex) => {
    setAvailability((prev) =>
      prev.map((day, index) =>
        index === dayIndex
          ? {
              ...day,
              slots: day.slots.filter((_, sIndex) => sIndex !== slotIndex),
            }
          : day
      )
    );
  };

  const disabled = availability.every((avail) => avail.is_unavailable === 1);

  const onSetAvailability = async () => {
    setLoading(true);
    try {
      await CalendarServices.setAvailability({ availability });
      fetchAvailability();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleShow = (day: string) => {
    setSelectedSlot((prev) => (prev === day ? null : day));
  };

  const handleCopySlot = (day: string) => {
    setSelect(day);
    handleShow(day);
  };
  const domNode = useClickOutSide(() => {
    setSelectedSlot(null);
  });

  const handleSelect = (day: string) => {
    if (selectedAvailability.includes(day)) {
      const filterAvailability = selectedAvailability.filter(
        (preAvail) => preAvail !== day
      );
      setSelectedAvailability(filterAvailability);
    } else {
      setSelectedAvailability([...selectedAvailability, day]);
    }
  };
  const applyCopy = () => {
    const findAvailability = availability.find((avail) => avail.day === select);
    const preSelectedAvailability = availability.map((item) => {
      if (selectedAvailability.includes(item.day)) {
        return {
          ...item,
          is_unavailable: findAvailability.is_unavailable,
          slots: findAvailability.slots,
        };
      } else {
        return item;
      }
    });
    setAvailability(preSelectedAvailability);
    setSelectedSlot(null);
    setSelectedAvailability([]);
  };
  return (
    <>
      <Box
        sx={{
          pt: "22px",
          px: "30px",
          pb: "34px",
          border: BORDERS.GRAY,
          borderRadius: "6px",
          height: "91%",
        }}
      >
        <Stack gap={"30px"} pb={"40px"}>
          <AtsTitleText
            text={"Set your weekly availability"}
            fs={16}
            fw={600}
            textColor="#000"
          />
          <Stack gap={"4px"}>
            <Box width={271}>
              <AtsSelect
                height="35px"
                placeholder="Select Timezone"
                selected={selectedTimezone}
                onSelect={(select: string) => setSelectedTimezone(select)}
                options={timezone}
              />
            </Box>
            <AtsTitleText
              text={"Current Timezone: GMT+05:30 (India Standard Time)"}
              fs={12}
              fw={400}
              textColor="#000"
            />
          </Stack>
        </Stack>
        <Box
          sx={{
            height: "calc(100% - 156px)",
          }}
        >
          <Stack
            gap={"44px"}
            sx={{
              height: "97%",
              overflow: "auto",
              py: "5px",
            }}
          >
            {loading
              ? Array.from(new Array(7)).map((_, index) => (
                  <Stack
                    key={index}
                    direction={"row"}
                    alignItems={"flex-start"}
                  >
                    <AtsRow width={171} gap={"15px"}>
                      <>
                        <Skeleton
                          variant="rectangular"
                          height={20}
                          width={100}
                        />
                      </>
                    </AtsRow>
                    <Stack gap={"10px"}>
                      <AtsRow gap={"8px"}>
                        <>
                          <Box width={145}>
                            <Skeleton variant="rectangular" height={40} />
                          </Box>
                          <AtsTitleText text={"-"} />
                          <Box width={145}>
                            <Skeleton variant="rectangular" height={40} />
                          </Box>
                          <Skeleton variant="rounded" height={20} width={20} />
                          <Skeleton variant="rounded" height={20} width={20} />
                        </>
                      </AtsRow>
                    </Stack>
                  </Stack>
                ))
              : availability.map((avail, index) => {
                  return (
                    <Stack
                      key={index}
                      direction={"row"}
                      alignItems={"flex-start"}
                    >
                      <AtsRow width={171} gap={"15px"}>
                        <>
                          <AtsAntSwitch
                            checked={avail.is_unavailable === 0}
                            onChange={(e) =>
                              handleToggleAvailability(e.target.checked, index)
                            }
                          />
                          <AtsTitleText text={avail.day.substring(0, 3)} />
                        </>
                      </AtsRow>
                      <Stack gap={"10px"}>
                        {avail.slots.length > 0 ? (
                          avail.slots.map((slot, slotIndex) => (
                            <AtsRow key={slotIndex} gap={"8px"}>
                              <>
                                <Box width={145}>
                                  <AtsTimePicker
                                    fs="14px"
                                    fw={500}
                                    value={dayjs(slot.start, "HH:mm")}
                                    onChange={(newTime) =>
                                      handleTimeChange(
                                        index,
                                        slotIndex,
                                        "start",
                                        newTime
                                      )
                                    }
                                  />
                                </Box>
                                <AtsTitleText text={"-"} />
                                <Box width={145}>
                                  <AtsTimePicker
                                    fs="14px"
                                    fw={500}
                                    value={dayjs(slot.end, "HH:mm")}
                                    onChange={(newTime) =>
                                      handleTimeChange(
                                        index,
                                        slotIndex,
                                        "end",
                                        newTime
                                      )
                                    }
                                  />
                                </Box>
                                {slotIndex === 0 ? (
                                  <AtsRow>
                                    <>
                                      <IconButton
                                        onClick={() =>
                                          handleAddSlot(index, slotIndex)
                                        }
                                      >
                                        <Add
                                          sx={{
                                            fontSize: 18,
                                          }}
                                        />
                                      </IconButton>
                                      <div
                                        className=""
                                        style={{
                                          position: "relative",
                                        }}
                                        // ref={domNode}
                                      >
                                        <IconButton
                                          onClick={() =>
                                            handleCopySlot(avail.day)
                                          }
                                        >
                                          <ContentCopy
                                            sx={{
                                              fontSize: 18,
                                            }}
                                          />
                                        </IconButton>
                                        <Box
                                          sx={{
                                            position: "absolute",
                                            top: 27,
                                            left: 10,
                                            zIndex: 9999,
                                            bgcolor: "white",
                                          }}
                                        >
                                          <Collapse
                                            in={avail.day === selectedSlot}
                                            sx={{
                                              borderRadius: "6px",
                                              boxShadow: `0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);`,
                                            }}
                                          >
                                            <Stack
                                              sx={{
                                                p: "10px",
                                              }}
                                              gap={"7px"}
                                            >
                                              {availability.map(
                                                (item, index) => (
                                                  <AtsRowBetween
                                                    width={130}
                                                    gap={"8px"}
                                                  >
                                                    <>
                                                      <AtsTitleText
                                                        text={item.day}
                                                        fs={12}
                                                        fw={500}
                                                      />
                                                      <AtsCheckbox
                                                        disabled={
                                                          item.day === select
                                                        }
                                                        checked={
                                                          item.day === select ||
                                                          selectedAvailability.includes(
                                                            item.day
                                                          )
                                                        }
                                                        onChange={() =>
                                                          handleSelect(item.day)
                                                        }
                                                      />
                                                    </>
                                                  </AtsRowBetween>
                                                )
                                              )}
                                              <AtsRoundedButton
                                                text="Apply"
                                                onClick={applyCopy}
                                              />
                                            </Stack>
                                          </Collapse>
                                        </Box>
                                      </div>
                                    </>
                                  </AtsRow>
                                ) : (
                                  <IconButton
                                    onClick={() =>
                                      handleRemoveSlot(index, slotIndex)
                                    }
                                  >
                                    <Close
                                      sx={{
                                        fontSize: 16,
                                      }}
                                    />
                                  </IconButton>
                                )}
                              </>
                            </AtsRow>
                          ))
                        ) : (
                          <AtsRow
                            sx={{
                              width: 277,
                              height: 40,
                              px: "18px",
                              borderRadius: "8px",
                              border: BORDERS.GRAY,
                              bgcolor: "#f0f0f0",
                            }}
                          >
                            <>
                              <AtsTitleText
                                text={"Unavailable"}
                                fs={14}
                                fw={500}
                                textColor={COLORS.DARK_GRAY}
                              />
                            </>
                          </AtsRow>
                        )}
                      </Stack>
                    </Stack>
                  );
                })}
          </Stack>
        </Box>
        <AtsRow justifyContent={"flex-end"}>
          <AtsRoundedButton
            text="Save"
            buttonWidth={100}
            disabled={disabled}
            onClick={onSetAvailability}
          />
        </AtsRow>
      </Box>
    </>
  );
};

export default WeeklyAvailability;

import {
  Badge,
  Check,
  Close,
  FiberManualRecord,
  InfoOutlined,
} from "@mui/icons-material";
import { Box, Button, Stack, useMediaQuery, useTheme } from "@mui/material";
import AtsCustomButton from "../../../../../../../../components/AtsCustomButton";
import AtsDateRangePicker from "../../../../../../../../components/AtsDateRangePicker";
import AtsIcon from "../../../../../../../../components/AtsIcon";
import AtsModal from "../../../../../../../../components/AtsModal";
import AtsRoundedButton from "../../../../../../../../components/AtsRoundedButton";
import AtsTitleText from "../../../../../../../../components/AtsTitleText";
import {
  getContractorTimesheetState,
  setIsApprovedAll,
  setSelectedDisputeFlowTab,
  setShowDisputeModal,
  setTimesheetsData,
} from "../../../../../../../../redux/slices/contractorTimesheetSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../redux/store";
import { BORDERS, COLORS } from "../../../../../../../../theme";
import { DisputeTabsEnum } from "../../../../../../../../types/contractorTypes";
import {
  formatDateRange,
  getDateFormatMMMDYYYYY,
} from "../../../../../../../../utils/dateUtils";
import ImportantThings from "../../../../../../../PostOpportunity/components/ImportantThings";
import ContractorTimesheetList from "../../components/ContractorTimesheetList";
import DisputedSuccessfully from "./modules/DisputedSuccessfully";
import DisputeEntries from "./modules/DisputeEntries";
import EvidenceDetails from "./modules/EvidenceDetails";
import AtsRow from "../../../../../../../../components/AtsRow";
import AtsRowBetween from "../../../../../../../../components/AtsRowBetween";

const text = `After disputing time entries, your Account Manager and our Finance team will conduct a thorough investigation in accordance with our Dispute Policy.`;
const text2 = `If the dispute is deemed valid, the corresponding hours will be waived from payment.`;
const text3 = `In cases where the dispute is found to be invalid, the outstanding hours will be billed in a separate invoice and included in the subsequent pay period.`;
const text4 = `If the dispute is partially valid, we will issue an adjusted invoice reflecting the difference, which will also be added to the next pay period.`;

const TimesheetTab = () => {
  const theme = useTheme();
  const isLargeXl = useMediaQuery(theme.breakpoints.up("xxl"));
  const dispatch = useAppDispatch();
  const {
    selectedContractorTimesheets,
    isDisputed,
    isApprovedAll,
    timesheetsData,
    selectedDisputeFlowTab,
    showDisputeModal,
  } = useAppSelector(getContractorTimesheetState);

  const handleEvent = (event, picker) => {
    const datePicker = formatDateRange(picker.startDate, picker.endDate);
  };

  const onOpenCloseModal = () => {
    dispatch(setShowDisputeModal(!showDisputeModal));
    dispatch(setSelectedDisputeFlowTab(DisputeTabsEnum.DisputeEntries));
    // dispatch()
  };

  const onDispute = () => {
    onOpenCloseModal();
  };
  const isDispute = timesheetsData.some((data) => data.isDisputed);
  const onApproveAll = () => {
    const approveAllData = timesheetsData.map((data) => ({
      ...data,
      isApproved: true,
    }));
    dispatch(setTimesheetsData(approveAllData));
    dispatch(setIsApprovedAll(true));
  };

  return (
    <>
      <Stack
        direction="row"
        alignItems={"center"}
        justifyContent={"space-between"}
        flexWrap={"wrap"}
        pb={"19px"}
        sx={{ borderBottom: BORDERS.GRAY }}
      >
        <AtsTitleText text={"Alex’s Timesheet"} fs={18} fw={600} />
        <Stack direction="row" alignItems={"center"} gap={"24px"}>
          {selectedContractorTimesheets.length > 0 && !isApprovedAll && (
            <Button
              onClick={onOpenCloseModal}
              sx={{
                border: "1px solid #e63030 !important",
                bgcolor: "white !important",
                color: "#e63030 !important",
                fontFamily: "Inter",
                borderRadius: "20px",
                textTransform: "capitalize",
                px: "13px",
                py: "5px",
                height: 29,
                width: 102,
                ":hover": {
                  border: "1px solid #e63030 !important",
                  bgcolor: "white !important",
                  color: "#e63030 !important",
                },
              }}
              endIcon={
                <InfoOutlined
                  sx={{
                    fontSize: 16,
                    transform: "rotate(-180deg)",
                    color: "#e63030",
                  }}
                />
              }
            >
              Dispute
            </Button>
          )}

          <Stack
            direction={"row"}
            alignItems={"center"}
            sx={{
              border: BORDERS.PRIMARY,
              borderRadius: "40px",

              px: "8px",
              height: "28px",
            }}
          >
            <Stack direction={"row"} alignItems={"center"}>
              <AtsCustomButton px={0} py={0}>
                <AtsIcon icon="icon-arrow-left-2" height="16px" />
              </AtsCustomButton>
              <AtsDateRangePicker
                handleEvent={handleEvent}
                handleClear={() => {}}
              >
                <button
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    cursor: "pointer",
                  }}
                >
                  <Stack direction={"row"} gap={"4px"} alignItems={"center"}>
                    <AtsCustomButton px={0} py={0}>
                      <AtsIcon
                        icon="icon-calendar-1"
                        height="16px"
                        color={COLORS.PRIMARY}
                      />
                    </AtsCustomButton>
                    <AtsTitleText
                      text={getDateFormatMMMDYYYYY(new Date().toString())}
                      fs={12}
                      fw={500}
                      textColor={COLORS.DARK_GRAY}
                    />
                    <AtsTitleText
                      text={"-"}
                      fs={12}
                      fw={500}
                      textColor={COLORS.DARK_GRAY}
                    />

                    <AtsTitleText
                      text={getDateFormatMMMDYYYYY(new Date().toString())}
                      fs={12}
                      fw={500}
                      textColor={COLORS.DARK_GRAY}
                    />
                  </Stack>
                </button>
              </AtsDateRangePicker>
              <AtsCustomButton px={0} py={0}>
                <AtsIcon icon="icon-arrow-right-3" height="16px" />
              </AtsCustomButton>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <ContractorTimesheetList />
      {(isDispute || isApprovedAll) && (
        <Stack
          direction={"row"}
          alignItems={"flex-start"}
          mt={"30px"}
          gap={"92px"}
        >
          <>
            <Stack
              direction={"row"}
              alignItems={"flex-start"}
              gap={"6px"}
              sx={{
                width: 484,
              }}
            >
              <AtsIcon
                icon="icon-info-circle"
                height="18px"
                fs={18}
                color="#E63030"
              />
              <Stack gap={"5px"}>
                <AtsTitleText
                  text={"Dispute resolution"}
                  fs={14}
                  fw={700}
                  textColor="#e63030"
                />
                <Stack direction={"row"} alignItems={"flex-start"} gap={"5px"}>
                  <FiberManualRecord
                    sx={{ fontSize: 6, color: COLORS.DARK_GRAY, mt: "5px" }}
                  />
                  <AtsTitleText
                    text={text}
                    fs={12}
                    fw={400}
                    textColor={COLORS.DARK_GRAY}
                    lineHeight={1.5}
                  />
                </Stack>
                <Stack direction={"row"} alignItems={"flex-start"} gap={"5px"}>
                  <FiberManualRecord
                    sx={{ fontSize: 6, color: COLORS.DARK_GRAY, mt: "5px" }}
                  />
                  <AtsTitleText
                    text={text2}
                    fs={12}
                    fw={400}
                    textColor={COLORS.DARK_GRAY}
                    lineHeight={1.5}
                  />
                </Stack>
                <Stack direction={"row"} alignItems={"flex-start"} gap={"5px"}>
                  <FiberManualRecord
                    sx={{ fontSize: 6, color: COLORS.DARK_GRAY, mt: "5px" }}
                  />
                  <AtsTitleText
                    text={text3}
                    fs={12}
                    fw={400}
                    textColor={COLORS.DARK_GRAY}
                    lineHeight={1.5}
                  />
                </Stack>
                <Stack direction={"row"} alignItems={"flex-start"} gap={"5px"}>
                  <FiberManualRecord
                    sx={{ fontSize: 6, color: COLORS.DARK_GRAY, mt: "5px" }}
                  />
                  <AtsTitleText
                    text={text4}
                    fs={12}
                    fw={400}
                    textColor={COLORS.DARK_GRAY}
                    lineHeight={1.5}
                  />
                </Stack>
              </Stack>
            </Stack>
            <Stack sx={{ flex: 1 }}>
              <Box
                sx={{
                  pb: "20px",
                  borderBottom: "1px solid",
                }}
              >
                <AtsRowBetween>
                  <>
                    <AtsTitleText
                      text={"Disputed"}
                      fs={14}
                      fw={400}
                      textColor="#e63030"
                    />
                    <AtsTitleText
                      text={"- 02.45 hrs"}
                      fs={14}
                      fw={400}
                      textColor="#e63030"
                    />
                  </>
                </AtsRowBetween>
              </Box>
              <Box
                sx={{
                  py: "20px",
                  borderBottom: "1px solid",
                }}
              >
                <AtsRowBetween>
                  <>
                    <AtsTitleText
                      text={"Billable time"}
                      fs={16}
                      fw={600}
                      textColor="#000"
                    />
                    <AtsTitleText
                      text={"40.00 hrs"}
                      fs={16}
                      fw={600}
                      textColor="#000"
                    />
                  </>
                </AtsRowBetween>
              </Box>
              <Stack
                sx={{
                  pt: "20px",
                }}
              >
                {!isApprovedAll && (
                  <AtsRoundedButton
                    text="Approve all"
                    isInitial
                    fw={400}
                    buttonHeight={38}
                    onClick={onApproveAll}
                  />
                )}
              </Stack>
            </Stack>
          </>
        </Stack>
      )}
      <Stack
        mt={3}
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <ImportantThings
          header="Important things to remember"
          subText="Timesheet entries will be considered all approved after 48 hours"
        />
        {!isApprovedAll && !isDispute && (
          <AtsRoundedButton
            text="Approve all"
            isInitial
            fw={400}
            buttonWidth={264}
            buttonHeight={38}
            onClick={onApproveAll}
          />
        )}
      </Stack>
      <AtsModal
        width={isLargeXl ? 690 : 633}
        height={655}
        open={showDisputeModal}
        onClose={onOpenCloseModal}
      >
        <Box height={"100%"}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{
              px: "30px",
              py: "20px",
              borderBottom: BORDERS.GRAY,
            }}
          >
            <AtsTitleText
              text={"Dispute Time"}
              fs={18}
              fw={600}
              textColor="#000"
            />
            <AtsCustomButton px={0} py={0} onClick={onOpenCloseModal}>
              <Close />
            </AtsCustomButton>
          </Stack>
          {isDisputed ? (
            <DisputedSuccessfully />
          ) : (
            <>
              <Stack direction={"row"} gap={"10px"} width={"100%"}>
                {Object.values(DisputeTabsEnum).map((tab, index) => (
                  <Stack width={"50%"} key={tab}>
                    <Stack
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        gap={"10px"}
                        py={"16px"}
                      >
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          justifyContent={"center"}
                          sx={{
                            bgcolor:
                              selectedDisputeFlowTab ===
                                DisputeTabsEnum.EvidenceDetails && index === 0
                                ? COLORS.PRIMARY
                                : selectedDisputeFlowTab === tab
                                ? COLORS.PRIMARY
                                : "white",
                            border: BORDERS.PRIMARY,
                            color: "white",
                            height: 24,
                            width: 24,
                            borderRadius: "50px",
                          }}
                        >
                          {selectedDisputeFlowTab ===
                            DisputeTabsEnum.EvidenceDetails && index === 0 ? (
                            <Check
                              sx={{
                                fontSize: 18,
                              }}
                            />
                          ) : (
                            <AtsTitleText
                              text={index + 1}
                              fs={14}
                              fw={selectedDisputeFlowTab === tab ? 600 : 400}
                              textColor={
                                selectedDisputeFlowTab === tab
                                  ? "white"
                                  : COLORS.PRIMARY
                              }
                            />
                          )}
                        </Stack>
                        <AtsTitleText
                          text={tab}
                          fs={14}
                          fw={
                            selectedDisputeFlowTab ===
                              DisputeTabsEnum.EvidenceDetails && index === 0
                              ? 400
                              : selectedDisputeFlowTab === tab
                              ? 600
                              : 400
                          }
                          textColor={
                            selectedDisputeFlowTab ===
                              DisputeTabsEnum.EvidenceDetails && index === 0
                              ? COLORS.PRIMARY
                              : selectedDisputeFlowTab === tab
                              ? COLORS.PRIMARY
                              : COLORS.LIGHT_GRAY
                          }
                        />
                      </Stack>
                    </Stack>
                    <Box
                      sx={{
                        height: "4px",
                        width: "100%",
                        bgcolor: "var(--border-gray)",
                        borderRadius: 0,
                      }}
                    >
                      <Box
                        sx={{
                          height: "100%",
                          width:
                            selectedDisputeFlowTab ===
                              DisputeTabsEnum.EvidenceDetails && index === 0
                              ? "100%"
                              : selectedDisputeFlowTab ===
                                DisputeTabsEnum.EvidenceDetails
                              ? "50%"
                              : "50%",
                          bgcolor:
                            selectedDisputeFlowTab ===
                              DisputeTabsEnum.EvidenceDetails && index === 0
                              ? "#3c854d"
                              : selectedDisputeFlowTab === tab
                              ? "#3c854d"
                              : "var(--border-gray)",
                        }}
                      />
                    </Box>
                  </Stack>
                ))}
              </Stack>
              {selectedDisputeFlowTab === DisputeTabsEnum.EvidenceDetails ? (
                <EvidenceDetails />
              ) : (
                <DisputeEntries />
              )}
            </>
          )}
        </Box>
      </AtsModal>
    </>
  );
};

export default TimesheetTab;

export enum AppointmentStatusCode {
  Overdue = "OVERDUE",
  due = "DUE",
  contractStart = "CONTRACT_START",
  contractEnd = "CONTRACT_END",
  interview = "INTERVIEW",
}
export enum CalendarEventTypes {
  All = "All",
  Interviews = "Interviews",
  Reminders = "Reminders",
  Invoices = "Invoices",
}

export const EVENT_STATUS_BG_COLORS = {
  OVERDUE: "#ffd6d6",
  DUE: "#def5e6",
  CONTRACT_START: "#d9d2ff",
  CONTRACT_END: "#d9d2ff",
  INTERVIEW: "#cdf4ff",
};
export const EVENT_STATUS_COLORS = {
  OVERDUE: "#af1f1f",
  DUE: "#0e4f28",
  CONTRACT_START: "#381d7f",
  CONTRACT_END: "#381d7f",
  INTERVIEW: "#3c4f74",
};
export type Appointment = {
  id: number;
  status: string;
  text: string;
  length: string;
};

export type Blockout = { id: number; name: string };

export type EventItem = {
  start?: Date;
  end?: Date;
  data?: { appointment?: Appointment; blockout?: Blockout };
  isDraggable?: boolean;
  allDay?: boolean;
  resourceId?: number;
};

export interface Slot {
  start: string;
  end: string;
}

export interface DayAvailability {
  day: string;
  is_unavailable: string | number;
  slots: Slot[];
}
export interface GetDayAvailabilityResponse {
  nid: string;
  availability: DayAvailability[];
}

export interface AllUserAvailabilityResponse {
  [email: string]: GetDayAvailabilityResponse;
}
export interface ConnectedGoogleCalendarParams {
  authorization: string;
  successRedirect: string;
  failureRedirect: string;
}
export interface GetAvailabilityOfUsersRequest {
  date: string;
  emails: string[];
}

export interface EventTime {
  dateTime: string;
  timeZone: string;
}

export interface EventData {
  start: EventTime;
  end: EventTime;
  title: string;
  description: string;
}
export interface GoogleCalendarEventResponse {
  email?: string;
  status: string;
  data?: EventData[];
}

import { Box, Skeleton, Stack } from "@mui/material";
import {
  Autocomplete,
  GoogleMap,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import { useState } from "react";
import AtsTextInput from "../../../components/AtsTextInput";
import AtsTitleText from "../../../components/AtsTitleText";
import JobCardWrapper from "../components/JobCardWrapper";
import {
  getPostOpportunityState,
  JobAddressProps,
  PostOpportunityProps,
  setPostOpportunity,
} from "../../../redux/slices/postOpportunitySlice";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import AtsIcon from "../../../components/AtsIcon";
import { COLORS } from "../../../theme";

const JobLocation = () => {
  const containerStyle = {
    width: "100%",
    height: "100%",
    borderRadius: "10px",
  };

  const center = {
    lat: -3.745,
    lng: -38.523,
  };

  const [mapCenter, setMapCenter] = useState(center);
  const [searchQuery, setSearchQuery] = useState("");
  const [autocomplete, setAutocomplete] = useState(null);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyBBNruyN-7FWCCu024zrKRaVBhRP8eCawA",
    libraries: ["places"],
  });
  const dispatch = useAppDispatch();
  const { postOpportunity } = useAppSelector(getPostOpportunityState);
  const handlePlaceSelect = () => {
    const place = autocomplete.getPlace();
    if (place.geometry) {
      const location = place.geometry.location;
      setMapCenter({ lat: location.lat(), lng: location.lng() });
      setSearchQuery(place.formatted_address);
      const addressComponents = place.address_components;
      let country = "";
      let administrative_area = "";
      let locality = "";
      let address_line1 = "";
      let postal_code = "";

      for (let component of addressComponents) {
        const types = component.types;
        if (types.includes("country")) {
          country = component.long_name;
        } else if (types.includes("administrative_area_level_1")) {
          administrative_area = component.short_name;
        } else if (types.includes("locality")) {
          locality = component.long_name;
        } else if (types.includes("sublocality_level_1")) {
          address_line1 = component.long_name;
        } else if (types.includes("postal_code")) {
          postal_code = component.long_name;
        }
      }
      const job_address = {
        country,
        administrative_area,
        locality,
        address_line1,
        postal_code,
      } as JobAddressProps;

      const jobAddresObj = {
        ...postOpportunity,
        job_address,
      } as PostOpportunityProps;
      dispatch(setPostOpportunity(jobAddresObj));
    }
  };

  const handleLoad = (autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  };

  if (!isLoaded) {
    return <Skeleton />;
  }

  return (
    <JobCardWrapper
      header="Where is your job located?"
      subText="Your address will only be shared with potential prospects when they are hired."
    >
      <Stack pr={1} mb={1} gap={1}>
        <Autocomplete onLoad={handleLoad} onPlaceChanged={handlePlaceSelect}>
          <AtsTextInput
            height="44px"
            placeholder="Search location"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            startAdornment={
              <AtsIcon
                icon="icon-location"
                color={COLORS.PRIMARY}
                fw={600}
                height="20px"
              />
            }
          />
        </Autocomplete>

        <Box
          sx={{
            minHeight: "400px",
          }}
        >
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={mapCenter}
            zoom={10}
          >
            <Marker position={mapCenter} />
          </GoogleMap>
        </Box>
      </Stack>
    </JobCardWrapper>
  );
};

export default JobLocation;

import { Box, Stack } from "@mui/material";
import React from "react";
import AtsTitleText from "../../../../../../../../components/AtsTitleText";
import AtsRow from "../../../../../../../../components/AtsRow";
import { DisputeResolutionTabsEnum } from "../../../../../../../../types/contractorTypes";
import { BG_COLORS, COLORS } from "../../../../../../../../theme";
import AtsIcon from "../../../../../../../../components/AtsIcon";

const tabs = [
  {
    title: DisputeResolutionTabsEnum.Disputed,
    bgColor: BG_COLORS.PRIMARY,
    color: COLORS.PRIMARY,
    width: 108,
  },
  {
    title: DisputeResolutionTabsEnum.UnderReview,
    bgColor: "#fff4e4",
    color: "#e07b04",
    width: 118,
  },
  {
    title: DisputeResolutionTabsEnum.Resolved,
    bgColor: "#caffd6",
    color: "#087f23",
    width: 108,
  },
];

const TimelineTabs = () => {
  const timelineStatus = DisputeResolutionTabsEnum.Resolved;
  return (
    <Stack
      sx={{
        bgcolor: "#f5f8ff",
        px: "30px",
        py: "18px",
        boxShadow: "0 2px 2px 0 rgba(0, 0, 0, 0.06)",
      }}
      gap={"8px"}
    >
      <AtsTitleText text="Timeline" textColor="#000" fs={16} fw={400} />
      <AtsRow>
        <>
          {tabs.map((tab, index) => {
            return (
              <AtsRow key={index}>
                <>
                  <AtsRow
                    gap={"5px"}
                    sx={{
                      bgcolor: tab.bgColor,
                      borderRadius: "30px",
                      justifyContent: "center",
                      width: tab.width,
                      height: 27,
                      ...(timelineStatus === tab.title && {
                        boxShadow: "0 4px 6px 0 rgba(0, 0, 0, 0.15)",
                      }),
                    }}
                  >
                    <>
                      {tab.title === DisputeResolutionTabsEnum.UnderReview && (
                        <AtsIcon
                          icon="icon-tick-circle"
                          height="14px"
                          fs={14}
                          fw={600}
                          color={tab.color}
                        />
                      )}
                      <AtsTitleText
                        text={tab.title}
                        fs={12}
                        fw={600}
                        textColor={tab.color}
                      />
                    </>
                  </AtsRow>
                  {tabs.length !== index + 1 && (
                    <Box
                      sx={{
                        width: 46,
                        borderBottom: "1px dashed #3366cc",
                      }}
                    ></Box>
                  )}
                </>
              </AtsRow>
            );
          })}
        </>
      </AtsRow>
    </Stack>
  );
};

export default TimelineTabs;

import { Grid, Skeleton, Stack } from "@mui/material";
import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BORDERS, COLORS } from "../../../../../../../../theme";
import AtsTitleText from "../../../../../../../../components/AtsTitleText";
import { dateFormatDDDDMMMMD } from "../../../../../../../../utils/dateUtils";
import InterviewButton from "./InterviewButton";
import moment from "moment";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../redux/store";
import {
  getInterviewState,
  setSelectedSlot,
} from "../../../../../../../../redux/slices/interviewSlice";
import { CalendarServices } from "../../../../../../../../Api/calendarServices";
import { AllUserAvailabilityResponse } from "../../../../../../../../types/calendarTypes";
import { isNotNullOrUndefined } from "../../../../../../../../utils/stringUtils";
import AtsRow from "../../../../../../../../components/AtsRow";

const timeSlot = [
  "09:00 AM",
  "09:30 AM",
  "10:00 AM",
  "10:30 AM",
  "11:00 AM",
  "11:30 AM",
  "12:00 AM",
  "01:00 PM",
  "01:30 PM",
  "02:00 PM",
  "02:30 PM",
];

interface IProps {
  allUserAvailability: AllUserAvailabilityResponse | null;
}

const TimeSlot = ({ allUserAvailability }: IProps) => {
  const dispatch = useAppDispatch();
  const { count, selectedDate, selectedSlot, createInterview } =
    useAppSelector(getInterviewState);
  const [loading, setLoading] = useState<boolean>(false);
  const [timeSlot, setTimeSlot] = useState([]);

  const selecteSlot = (slot: string) => {
    if (selectedSlot === slot) {
      dispatch(setSelectedSlot(""));
    } else {
      dispatch(setSelectedSlot(slot));
    }
  };
  const unAvailabilityOfUsers = async () => {
    try {
      const unAvailabilityResponse =
        await CalendarServices.getUnAvailabilityOfUsers({
          emails: createInterview.interviewer_email,
          date: moment(selectedDate as any).format("YYYY-MM-DD"),
        });
    } catch (error) {}
  };

  const getDay = (dateStr: string) => {
    const date = new Date(dateStr);

    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    const dayIndex = date.getDay();

    const dayName = daysOfWeek[dayIndex];
    return dayName;
  };

  const googleEventsOfUsers = async () => {
    dispatch(setSelectedSlot(""));
    setLoading(true);
    try {
      const formattedSelectedDate = moment(selectedDate as any).format(
        "YYYY-MM-DD"
      );
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const todayTime = today.getTime();
      const dayNameSelected = getDay(selectedDate as any);

      const googleEventResponse =
        await CalendarServices.getAllCalendarEventsOfUsers({
          emails: createInterview.interviewer_email,
          date: formattedSelectedDate,
        });

      const processedEvents = googleEventResponse.map((event) => {
        const getUserSlot = allUserAvailability[event?.email];

        if (isNotNullOrUndefined(event.data)) {
          const todayEvents = event.data.filter((event) => {
            const eventDate = new Date(event.start.dateTime);
            eventDate.setHours(0, 0, 0, 0);
            return eventDate.getTime() === todayTime;
          });

          if (todayEvents.length) {
            return todayEvents.reduce((accumulatedSlots, todayEvent) => {
              const dayName = getDay(todayEvent.start.dateTime);
              const filterUserSlot = getUserSlot.availability.find(
                (avail) => avail.day === dayName
              );

              if (filterUserSlot) {
                const availableSlot = filterUserSlot.slots.find(
                  (slot) =>
                    slot.start !==
                    moment(todayEvent.start.dateTime).format("HH:mm")
                );
                if (availableSlot) accumulatedSlots.push(availableSlot);
              }
              return accumulatedSlots;
            }, []);
          } else {
            const filterUserSlot = getUserSlot.availability.find(
              (avail) => avail.day === dayNameSelected
            );
            return filterUserSlot ? filterUserSlot.slots : [];
          }
        }

        return [];
      });

      setTimeSlot(processedEvents.flat());
    } catch (error) {
      console.error("Error fetching google events", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isNotNullOrUndefined(allUserAvailability)) {
      unAvailabilityOfUsers();
      googleEventsOfUsers();
    }
  }, [selectedDate, allUserAvailability]);

  return (
    <>
      <Container maxWidth="xs" sx={{ pt: "7px" }}>
        <Stack gap={1}>
          <Stack
            sx={{
              pb: 2,
              borderBottom: BORDERS.GRAY,
            }}
          >
            <AtsTitleText
              fs={14}
              fw={600}
              text={dateFormatDDDDMMMMD(selectedDate)}
            />
          </Stack>
          <Stack pt={2}>
            <AtsTitleText
              sx={{
                fontSize: 11,
                fontWeight: 500,
                color: COLORS.LIGHT_GRAY,
              }}
              text="SELECT TIME SLOT"
            />
            <AtsRow
              columnGap={"20px"}
              rowGap={"10px"}
              flexWrap={"wrap"}
              mt={"10px"}
            >
              <>
                {loading
                  ? Array.from(new Array(9)).map((_, index) => (
                      <Skeleton
                        key={index}
                        variant="rectangular"
                        height={35}
                        width={100}
                      />
                    ))
                  : timeSlot.map((slot, index) => {
                      const time = moment(slot?.start, "HH:mm").format(
                        "hh:mm A"
                      );
                      return (
                        <InterviewButton
                          key={index}
                          value={time}
                          isSelected={selectedSlot === time}
                          onClick={() => selecteSlot(time)}
                        />
                      );
                    })}
              </>
            </AtsRow>
          </Stack>
        </Stack>
      </Container>
    </>
  );
};

export default TimeSlot;

import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import {
  Container,
  Grid,
  IconButton,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import AtsDateCalenderPicker from "../../../../../../../../components/AtsDateCalenderPicker";
import AtsText from "../../../../../../../../components/AtsText";
import AtsTitleText from "../../../../../../../../components/AtsTitleText";
import {
  getInterviewState,
  setCount,
  setSelectedDate,
  setSelectedSlot,
} from "../../../../../../../../redux/slices/interviewSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../redux/store";
import { BORDERS, COLORS } from "../../../../../../../../theme";
import { dateFormatDDDDMMMMD } from "../../../../../../../../utils/dateUtils";
import InterviewButton from "./InterviewButton";
import { CalendarServices } from "../../../../../../../../Api/calendarServices";
import moment from "moment";
import TimeSlot from "./TimeSlot";
import { AllUserAvailabilityResponse } from "../../../../../../../../types/calendarTypes";

const InterviewDateTimeSelection = () => {
  const dispatch = useAppDispatch();
  const { count, selectedDate, selectedSlot, createInterview } =
    useAppSelector(getInterviewState);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const min = [15, 30, 60];
  const [minIndex, setMinIndex] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [allUserAvailability, setAllUserAvailability] =
    useState<AllUserAvailabilityResponse | null>(null);

  const handleMinChange = (direction: "NEXT" | "PREV") => {
    if (direction === "NEXT") {
      setMinIndex((prevIndex) => (prevIndex + 1) % min.length);
    } else {
      setMinIndex((prevIndex) => (prevIndex - 1 + min.length) % min.length);
    }
  };

  const handleDateChange = (newDate) => {
    dispatch(setSelectedDate(newDate));
  };

  console.log("selectedDate", selectedDate);

  const fetchAvailabilityOfUsers = async () => {
    setLoading(true);
    try {
      const availabilityResponse =
        await CalendarServices.getAvailabilityOfUsers({
          emails: createInterview.interviewer_email,
        });
      setAllUserAvailability(availabilityResponse);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAvailabilityOfUsers();
  }, []);

  useEffect(() => {
    dispatch(setCount(min[minIndex]));
  }, [minIndex]);

  return (
    <Stack>
      <Stack sx={{ pt: "30px", pb: 4 }}>
        <AtsTitleText
          fs={14}
          fw={600}
          textColor={COLORS.LIGHT_GRAY}
          text="Select Duration of the interview"
        />
        <Stack
          direction={"row"}
          sx={{
            alignItems: "center",
          }}
        >
          <IconButton
            disabled={minIndex === 0}
            onClick={() => handleMinChange("PREV")}
          >
            <RemoveCircleOutline />
          </IconButton>
          <AtsTitleText
            fs={14}
            fw={400}
            textColor={"#000"}
            text={`${min[minIndex]} Mins`}
          />

          <IconButton
            disabled={minIndex === 2}
            onClick={() => {
              handleMinChange("NEXT");
            }}
          >
            <AddCircleOutline />
          </IconButton>
        </Stack>
      </Stack>
      <Stack>
        <AtsTitleText
          fs={14}
          fw={600}
          textColor={COLORS.LIGHT_GRAY}
          text="Select a Date and Time"
        />
      </Stack>
      <Stack direction={isMobile ? "column" : "row"} gap={2}>
        <Stack sx={{ flex: 1 }}>
          {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              sx={{
                margin: 0,
                ".MuiPickersCalendarHeader-root": {
                  marginTop: 0,
                  borderBottom: BORDERS.GRAY,
                },
                ".Mui-selected": {
                  color: "#3366cc !important",
                  bgcolor: "#e2ebff !important",
                  fontWeight: 600,
                },
                ".MuiPickersCalendarHeader-label": {
                  fontSize: 14,
                  color: COLORS.BLACK,
                },
              }}
              value={selectedDate}
              onChange={handleDateChange}
              minDate={dayjs(new Date())}
              shouldDisableDate={disableWeekends}
            />
          </LocalizationProvider> */}
          <AtsDateCalenderPicker
            onChange={handleDateChange}
            value={selectedDate}
            minDate={new Date()}
          />
        </Stack>
        <TimeSlot allUserAvailability={allUserAvailability} />
      </Stack>
    </Stack>
  );
};

export default InterviewDateTimeSelection;

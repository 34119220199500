import { Box, Drawer } from "@mui/material";
import {
  getContractorDetailsState,
  setInitialContractorDetailsState,
  setIsContractorDetailsDrawerOpen,
} from "../../../../../../redux/slices/contractorSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/store";
import ContractorDetailBottomSection from "./modules/ContractorDetailBottomSection";
import ContractorDetailsTopSection from "./modules/ContractorDetailsTopSection";
import { setInitialContractorTimeSheetState } from "../../../../../../redux/slices/contractorTimesheetSlice";

const ContractorDetailsDrawer = () => {
  const dispatch = useAppDispatch();
  const { isContractorDetailsDrawerOpen } = useAppSelector(
    getContractorDetailsState
  );
  return (
    <>
      <Drawer
        sx={{}}
        PaperProps={{
          sx: {
            borderTopLeftRadius: "20px",
            borderBottomLeftRadius: "20px",
            width: "1130px",
            overflow: "hidden",
          },
        }}
        anchor="right"
        open={isContractorDetailsDrawerOpen}
        onClose={() => onCloseContractorDetailsDrawer(dispatch)}
      >
        <Box
          sx={{
            bgcolor: "background.paper",
            border: "0px solid #000",
            boxShadow: 24,
            outline: "0",
            borderTopLeftRadius: 3,
            borderBottomLeftRadius: 3,
            pb: 2,
            height: "100%",
          }}
        >
          <ContractorDetailsTopSection />
          <ContractorDetailBottomSection />
        </Box>
      </Drawer>
    </>
  );
};

export default ContractorDetailsDrawer;

export const onCloseContractorDetailsDrawer = (dispatch: any) => {
  dispatch(setInitialContractorDetailsState());
  dispatch(setInitialContractorTimeSheetState());
  dispatch(setIsContractorDetailsDrawerOpen(false));
};

import { Box, Stack } from "@mui/material";
import React, { useMemo, useState } from "react";
import AtsTitleText from "../../../../../../components/AtsTitleText";
import JobsSwitch from "../../../../../Dashboard/PostedJobs/JobsSwitch";
import { ProjectTimesheetTabsEnum } from "../../../../../../enums/projectEnums";
import AtsIcon from "../../../../../../components/AtsIcon";
import AtsRoundedButton from "../../../../../../components/AtsRoundedButton";
import {
  BG_COLORS,
  BORDERS,
  BOX_SHADOW,
  COLORS,
} from "../../../../../../theme";
import PendingTimesheetList from "./modules/PendingTimesheetList";
import ApprovedTimesheet from "./modules/ApprovedTimesheet";
import PendingTimeSheet from "./modules/PendingTimeSheet";
import DisputedTimesheet from "./modules/DisputedTimesheet";
import ContractorDetailsDrawer from "../ContractorDetails/ContractorDetailsDrawer";
import { DisputeResolutionProvider } from "../../../../../../providers/DisputeResolutionProvider";

const Timesheets = () => {
  const [selectedStatus, setSelectedStatus] =
    useState<ProjectTimesheetTabsEnum>(ProjectTimesheetTabsEnum.Pending);

  const taskStatuses = Object.keys(ProjectTimesheetTabsEnum).map((status) => ({
    title: status,
    type: status,
  }));

  const contractorDetailsDrawerMemo = useMemo(
    () => <ContractorDetailsDrawer />,
    []
  );
  return (
    <>
      <Stack mb={"24px"}>
        <AtsTitleText fs={18} fw={600} text={`10 timesheets`} />
      </Stack>
      <Stack direction={"row"} sx={{ pb: "25px" }}>
        <JobsSwitch
          width="62px"
          jobs={taskStatuses}
          active={selectedStatus}
          onChange={(value) => {
            setSelectedStatus(value as ProjectTimesheetTabsEnum);
          }}
        />
      </Stack>
      {selectedStatus === ProjectTimesheetTabsEnum.Pending && (
        <Stack direction={"row"} sx={{ pb: "25px" }}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            gap={"9px"}
            sx={{
              width: "100%",
              padding: "9px 16px 10px 16px",
              borderRadius: "6px",
              bgcolor: "#ffefea",
            }}
          >
            <AtsIcon
              icon="icon-danger"
              color="#EC7249"
              height="16px"
              fw={600}
            />
            <AtsTitleText
              fs={14}
              fw={400}
              textColor="#000"
              text={
                "All timesheets must be approved within 48 hours; after which they will be auto-approved."
              }
            />
          </Stack>
        </Stack>
      )}
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        flexWrap={"wrap"}
        sx={{ pb: "24px" }}
      >
        <Stack direction={"row"} alignItems={"center"} gap={"20px"} height={30}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            minHeight={30}
            width={232}
            sx={{
              px: "16px",
              border: BORDERS.GRAY,
              borderRadius: "38px",
            }}
          >
            <input
              type="text"
              className="customInput"
              placeholder="Search contractor name"
              style={{
                width: "100%",
              }}
            />
            <AtsIcon icon="icon-search-normal-1" height="16px" />
          </Stack>
          <Stack
            direction={"row"}
            alignItems={"center"}
            px={"14px"}
            py={"6px"}
            gap={"6px"}
            sx={{
              border: BORDERS.GRAY,
              borderRadius: "48px",
            }}
          >
            <AtsTitleText
              text={"Team"}
              fs={14}
              fw={500}
              textColor={COLORS.DARK_GRAY}
            />
            <AtsIcon icon="icon-arrow-down-1" height="16px" />
          </Stack>
        </Stack>
        {selectedStatus === ProjectTimesheetTabsEnum.Pending && (
          <Stack direction={"row"} alignItems={"center"} gap={"24px"}>
            <AtsRoundedButton
              text="Remind to approve"
              buttonHeight={29}
              buttonWidth={166}
              buttonVariant="blackOutline"
              isInitial
            />
            <AtsRoundedButton
              text="Approve all"
              buttonHeight={29}
              buttonWidth={132}
            />
          </Stack>
        )}
      </Stack>
      <Stack
        sx={{
          height: "calc(100% - 235px)",
          border: BORDERS.GRAY,
          borderRadius: "6px",
          boxShadow: BOX_SHADOW.GRAY,
        }}
      >
        <Box
          sx={{
            px: "22px",
            py: "19px",
            bgcolor: BG_COLORS.GRAY,
            borderBottom: BORDERS.GRAY,
            borderTopLeftRadius: "6px",
            borderTopRightRadius: "6px",
          }}
        >
          <AtsTitleText
            text={"Aug 05, 2024 - Aug 11, 2024"}
            fs={16}
            fw={500}
            textColor="#000"
          />
        </Box>
        <Box
          sx={{
            height: "calc(100% - 80px)",
            overflow: "auto",
          }}
        >
          {/* <PendingTimesheetList /> */}
          <Box px={"30px"} pb={"27px"}>
            {selectedStatus === ProjectTimesheetTabsEnum.Approved ? (
              <ApprovedTimesheet />
            ) : selectedStatus === ProjectTimesheetTabsEnum.Disputed ? (
              <DisputeResolutionProvider>
                <DisputedTimesheet />
              </DisputeResolutionProvider>
            ) : (
              <PendingTimeSheet />
            )}
          </Box>
        </Box>
      </Stack>
      {contractorDetailsDrawerMemo}
    </>
  );
};

export default Timesheets;

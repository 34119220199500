import { Box, Stack } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ProjectDetailsTabs from "./modules/ProjectDetailsTabs";
import { useAppDispatch, useAppSelector } from "../../../../../redux/store";
import {
  getProjectDetailsState,
  setProjectDocsList,
  setProjectDocuments,
  setProjectTab,
} from "../../../../../redux/slices/projectDetailsSlice";
import CompanyDetails from "./modules/CompanyDetails";
import { ProjectDetailsTabsEnum } from "../../../../../enums/projectEnums";
import PolicyDocuments from "./modules/PolicyDocuments";
import TeamMember from "./modules/TeamMember";
import HeathAndSafety from "./modules/HeathAndSafety";
import ProjectDocuments from "./modules/ProjectDocuments";
import { ProjectService } from "../../../../../Api/projectServices";
import { ProjectDocumentResponse } from "../../../../../types/projectTypes";
import { useParams } from "react-router-dom";

const DetailsOfProject = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { selectedProjectDetailsTab, projectTab } = useAppSelector(
    getProjectDetailsState
  );
  const [loading, setLoading] = useState<boolean>(false);

  const companyDetailsMemo = useMemo(() => <CompanyDetails />, []);
  const policyDocumentsMemo = useMemo(() => <PolicyDocuments />, []);
  const heathAndSafetyMemo = useMemo(() => <HeathAndSafety />, []);
  const projectDocumentsMemo = useMemo(() => <ProjectDocuments />, []);
  const teamMemberMemo = useMemo(() => <TeamMember />, []);

  const getDocumentType = async () => {
    setLoading(true);
    try {
      const response = await ProjectService.getDocumentTypeForProject();
      if (response && response.length) {
        const filterProjectDocument = response.filter(
          (docs) => !docs.parent_id.length
        );

        const projectTypeData = [
          {
            name: "Company",
            id: "Company",
            description:
              "Complete all fields related to company details, position specifics, and contact information.",
            parent_id: "",
          },
          ...filterProjectDocument,
          {
            name: "Team Members",
            id: "Team Members",
            description:
              "Complete all fields related to company details, position specifics, and contact information.",
            parent_id: "",
          },
        ];
        dispatch(setProjectTab(projectTypeData));
        dispatch(setProjectDocuments(response));
      } else {
        const projectTypeData = [
          {
            name: "Company",
            id: "Company",
            description:
              "Complete all fields related to company details, position specifics, and contact information.",
            parent_id: "",
          },
          {
            name: "Team Members",
            id: "Team Members",
            description:
              "Complete all fields related to company details, position specifics, and contact information.",
            parent_id: "",
          },
        ];
        dispatch(setProjectTab(projectTypeData));
      }
    } catch (error) {
      const projectTypeData = [
        {
          name: "Company",
          id: "Company",
          description:
            "Complete all fields related to company details, position specifics, and contact information.",
          parent_id: "",
        },
        {
          name: "Team Members",
          id: "Team Members",
          description:
            "Complete all fields related to company details, position specifics, and contact information.",
          parent_id: "",
        },
      ];
      dispatch(setProjectTab(projectTypeData));
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getDocumentList = async () => {
    try {
      const response = await ProjectService.getDocumentByProjectId({
        project_id: id,
      });

      const documents = response.results.map((docs) => ({
        ...docs,
        attachments: docs.attachments.map((attachment) => ({
          ...attachment,
          nid: docs.nid,
        })),
      }));
      dispatch(setProjectDocsList(documents));
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  useEffect(() => {
    getDocumentType();
    getDocumentList();
  }, []);

  const renderDetailsComponents = useCallback(() => {
    if (selectedProjectDetailsTab.id === ProjectDetailsTabsEnum.COMPANY) {
      return companyDetailsMemo;
    } else if (
      selectedProjectDetailsTab.id === ProjectDetailsTabsEnum.TEAM_MEMBER
    ) {
      return teamMemberMemo;
    } else {
      const findTab = projectTab.find(
        (tab) => tab.id === selectedProjectDetailsTab.id
      );
      if (findTab?.name === ProjectDetailsTabsEnum.POLICY_DOCUMENTS) {
        return policyDocumentsMemo;
      } else if (findTab?.name === ProjectDetailsTabsEnum.HEALTH_SAFETY) {
        return policyDocumentsMemo;
      } else if (findTab?.name === ProjectDetailsTabsEnum.PROJECT_DOCUMENTS) {
        return projectDocumentsMemo;
      }
    }
  }, [selectedProjectDetailsTab]);
  return (
    <>
      <Stack direction={"row"} gap={"63px"} height={"98%"}>
        <ProjectDetailsTabs projectTab={projectTab} loading={loading} />
        <Box
          sx={{
            width: "100%",
            height: "100%",
          }}
        >
          {renderDetailsComponents()}
        </Box>
      </Stack>
    </>
  );
};

export default DetailsOfProject;

import React, { useEffect, useState } from "react";

import { Stack } from "@mui/material";

import { Box } from "@mui/material";
import AtsCheckbox from "../../../../../../../components/AtsCheckbox";
import AtsDataTable from "../../../../../../../components/AtsDataTable";
import AtsTitleText from "../../../../../../../components/AtsTitleText";
import { setIsContractorDetailsDrawerOpen } from "../../../../../../../redux/slices/contractorSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../redux/store";
import { BG_COLORS, BORDERS, COLORS } from "../../../../../../../theme";
import {
  ContractorTimesheetProps,
  getContractorTimesheetState,
  setSelectedContractorTimesheets,
} from "../../../../../../../redux/slices/contractorTimesheetSlice";
import AtsIcon from "../../../../../../../components/AtsIcon";

interface IColumnProps {
  key: string;
  header: string;
  width: string;
  minWidth: string;
}

const LEAD_COLUMN: IColumnProps[] = [
  {
    key: "id",
    header: "",
    width: "20px",
    minWidth: "20px",
  },
  {
    key: "date",
    header: "Date",
    width: "auto",
    minWidth: "100px",
  },

  {
    key: "task",
    header: "Task",
    width: "auto",
    minWidth: "180px",
  },
  {
    key: "DescriptionOfWork",
    header: "Description of work",
    width: "auto",
    minWidth: "150px",
  },

  {
    key: "startEndTime",
    header: "Start time - end time",
    width: "auto",
    minWidth: "150px",
  },
  {
    key: "time",
    header: "Time",
    width: "70px",
    minWidth: "70px",
  },
];

const cellStyles = {
  padding: "9px 15px",
};
const firstCellStyles = {
  padding: "9px 0px 9px 10px",
};

const ContractorTimesheetList = () => {
  const dispatch = useAppDispatch();

  const { selectedContractorTimesheets, isApprovedAll, timesheetsData } =
    useAppSelector(getContractorTimesheetState);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  console.log(
    "timesheetsData",
    timesheetsData.filter((timesheet) => !timesheet.isDisputed),
    selectedContractorTimesheets,
    selectAll
  );
  useEffect(() => {
    setSelectAll(
      selectedContractorTimesheets.length ===
        timesheetsData.filter((timesheet) => !timesheet.isDisputed).length
    );
  }, [selectedContractorTimesheets]);

  const handleUserSelect = (selectedTimesheet: ContractorTimesheetProps) => {
    if (selectedContractorTimesheets.includes(selectedTimesheet)) {
      const filterContractors = selectedContractorTimesheets.filter(
        (userId) => userId.id !== selectedTimesheet.id
      );
      dispatch(setSelectedContractorTimesheets(filterContractors));
    } else {
      dispatch(
        setSelectedContractorTimesheets([
          ...selectedContractorTimesheets,
          selectedTimesheet,
        ])
      );
    }
  };

  const handleSelectAllUser = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    dispatch(
      setSelectedContractorTimesheets(
        newSelectAll
          ? timesheetsData
              .filter((timesheet) => !timesheet.isDisputed)
              .map((user) => user)
          : []
      )
    );
  };

  const openContractDetailsDrawer = (contractor: ContractorTimesheetProps) => {
    dispatch(setIsContractorDetailsDrawerOpen(true));
  };
  const customizeRow = (
    column: IColumnProps,
    contractor: ContractorTimesheetProps
  ) => {
    switch (column.key) {
      case "id":
        return (
          <td style={firstCellStyles} key={column.key}>
            <Stack direction={"row"} alignItems={"center"} gap={0.3}>
              {isApprovedAll ? (
                ""
              ) : contractor.isDisputed ? (
                <AtsIcon
                  icon="icon-info-circle"
                  height="22px"
                  fs={22}
                  color="#E63030"
                />
              ) : (
                <AtsCheckbox
                  checked={selectedContractorTimesheets
                    .map((timesheet) => timesheet.id)
                    .includes(contractor.id)}
                  // onChange={() => handleUserSelect(contractor.id)}
                  p={0}
                />
              )}
            </Stack>
          </td>
        );
      case "date":
      case "task":
      case "DescriptionOfWork":
      case "startEndTime":
        return (
          <td style={cellStyles} key={column.key}>
            <Stack direction={"row"} gap={1}>
              <AtsTitleText
                fs={12}
                fw={400}
                textColor={COLORS.DARK_GRAY}
                text={contractor[column.key]}
              />
            </Stack>
          </td>
        );

      case "time":
        return (
          <td
            style={{
              ...cellStyles,
              backgroundColor: contractor.isDisputed
                ? "#fff3f3"
                : selectedContractorTimesheets
                    .map((timesheet) => timesheet.id)
                    .includes(contractor.id)
                ? BG_COLORS.PRIMARY
                : isApprovedAll
                ? "#e1ffe8"
                : "#f0f5ff",
            }}
            key={column.key}
          >
            <Stack direction={"row"} gap={1}>
              <AtsTitleText
                fs={14}
                fw={600}
                textColor={COLORS.BLACK}
                text={contractor[column.key]}
              />
            </Stack>
            {contractor.isDisputed && (
              <Box
                sx={{
                  position: "absolute",
                  right: 20,
                }}
              >
                <AtsTitleText
                  fs={11}
                  fw={600}
                  textColor={"#e63030"}
                  text={"Disputed -2.45 hrs"}
                />
              </Box>
            )}
          </td>
        );
      default:
        return null;
    }
  };

  const renderRowComponent = () => {
    const leadsData = timesheetsData.map((value, index: number) => {
      console.log("value.isDisputed", value.isDisputed);
      return (
        <React.Fragment key={index}>
          <tr
            style={{
              borderBottom: BORDERS.GRAY,
              minHeight: "44px",
              height: 67,
              margin: "0px 8px",
              paddingTop: "10px",
              paddingBottom: "10px",
              cursor: value.isDisputed || isApprovedAll ? "default" : "pointer",
              position: "relative",
              backgroundColor: value.isDisputed
                ? "#fff3f3"
                : selectedContractorTimesheets
                    .map((timesheet) => timesheet.id)
                    .includes(value.id)
                ? BG_COLORS.PRIMARY
                : "transparent",
            }}
            onClick={
              value.isDisputed || isApprovedAll
                ? () => {}
                : () => handleUserSelect(value)
            }
          >
            {LEAD_COLUMN.map((val: IColumnProps) => customizeRow(val, value))}
          </tr>
        </React.Fragment>
      );
    });
    return (
      <>
        {leadsData}
        <tr>
          <td
            colSpan={9}
            style={{
              height: "53px",
              backgroundColor: "#f0f5ff",
            }}
          >
            <Stack direction={"row"} alignItems={"center"}>
              <Stack
                sx={{
                  textAlign: "end",
                  flexGrow: 1,
                  pr: "10px",
                }}
              >
                <AtsTitleText
                  fs={14}
                  fw={600}
                  textColor={COLORS.BLACK}
                  text={"Total"}
                />
              </Stack>
              <Box
                sx={{
                  ...cellStyles,
                  width: "70px",
                }}
              >
                <AtsTitleText
                  fs={14}
                  fw={600}
                  textColor={COLORS.BLACK}
                  text={"42.50 hrs"}
                />
              </Box>
            </Stack>
          </td>
        </tr>
      </>
    );
  };

  const headerComponent = () => {
    const isIndeterminate =
      selectedContractorTimesheets.length > 0 &&
      selectedContractorTimesheets.length <
        timesheetsData.filter((timesheet) => !timesheet.isDisputed).length;
    const headers = LEAD_COLUMN.map((column: IColumnProps, index: number) => (
      <>
        <th
          className="customTH"
          style={{
            width: index === 0 ? "20px" : column.width,
            minWidth: index === 0 ? "20px" : column.minWidth,
            padding: index === 0 ? "15px 0px 15px 10px" : "18px 15px",
            textAlign: "start",
            backgroundColor:
              column.header === "Time"
                ? isApprovedAll
                  ? "#e1ffe8"
                  : "#f0f5ff"
                : "transparent",
          }}
        >
          {index === 0 && !isApprovedAll ? (
            <AtsCheckbox
              checked={selectAll}
              indeterminate={isIndeterminate}
              onChange={handleSelectAllUser}
              p={0}
            />
          ) : (
            <Stack direction={"row"} alignItems={"center"} gap={1}>
              <AtsTitleText
                text={column.header}
                fs={12}
                fw={600}
                textColor="#000"
                isCursor
              />
            </Stack>
          )}
        </th>
      </>
    ));
    return headers;
  };
  return (
    <Box>
      <AtsDataTable
        data={timesheetsData}
        theadComponent={headerComponent}
        rowComponent={renderRowComponent}
        loading={false}
      />
    </Box>
  );
};

export default ContractorTimesheetList;

import { useEffect, useState } from "react";
import { UserManagementServices } from "../../../../Api/userManagementServices";
import AtsTitleText from "../../../../components/AtsTitleText";
import { UserImageResponse } from "../../../../types/userManagementTypes";
import { isNotNullOrUndefined } from "../../../../utils/stringUtils";

interface IProps {
  teamMember: string[];
}

const UserImageForProject = ({ teamMember }: IProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [images, setImages] = useState<UserImageResponse[]>([]);

  const getImages = async () => {
    setLoading(true);
    try {
      const email = teamMember.join(",");
      const response = await UserManagementServices.getUserImage({ email });
      setImages(response.results);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getImages();
  }, []);

  return (
    <>
      <div className="avatar-group">
        {images.length > 0 &&
          images.map((item, index) => {
            const image = isNotNullOrUndefined(item.image)
              ? item.image
              : "https://images.unsplash.com/photo-1499952127939-9bbf5af6c51c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTF8fHBlcnNvbnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60";
            return (
              <div key={item.uid} className="avatar">
                <img src={image} alt={`Image1${index}`} />
              </div>
            );
          })}
        {images.length > 4 && (
          <div className="avatar">
            <div className="plan">
              <AtsTitleText
                text={`+ ${images.length - 4}`}
                textColor="#000"
                fs={12}
                fw={600}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default UserImageForProject;

import { Stack } from "@mui/material";
import React from "react";
import AtsRow from "../../../../components/AtsRow";
import AtsTitleText from "../../../../components/AtsTitleText";
import { COLORS } from "../../../../theme";
import AtsRoundedButton from "../../../../components/AtsRoundedButton";
import { Add } from "@mui/icons-material";

const AddSpecificHours = () => {
  return (
    <>
      <Stack gap={"6px"} pb={"12px"}>
        <AtsRow>
          <>
            <AtsTitleText
              text={"Add Specific Hours"}
              fs={16}
              fw={600}
              textColor="#000"
            />
          </>
        </AtsRow>
        <AtsTitleText
          text={"Set custom hours for a particular day."}
          fs={14}
          fw={500}
          textColor={COLORS.DARK_GRAY}
        />
      </Stack>
      <AtsRow>
        <AtsRoundedButton
          text="Add"
          startIcon={<Add />}
          buttonVariant="outline"
          buttonWidth={80}
          buttonHeight={32}
        />
      </AtsRow>
    </>
  );
};

export default AddSpecificHours;

import { useState } from "react";
import { JobsService } from "../../../Api/jobsListingServices";
import StepComponent from "../../../components/common/StepComponent";
import { getAuthState } from "../../../redux/slices/authSlice";
import {
  getPostOpportunityState,
  PostOpportunityProps,
  setActiveStep,
  setActiveStep2,
  setActiveStep3,
  setIsPosted,
  setJobDetailsById,
} from "../../../redux/slices/postOpportunitySlice";
import { useAppDispatch, useAppSelector } from "../../../redux/store";
import { getStep1 } from "../PostOpportunity";

const Stepper = () => {
  const dispatch = useAppDispatch();
  const { userInfo } = useAppSelector(getAuthState);
  const {
    activeStep,
    activeStep2,
    activeStep3,
    isDisable,
    postOpportunity,
    jobDetailsById,
    selectedConfirm,
    isEdit,
  } = useAppSelector(getPostOpportunityState);

  const [loading, setLoading] = useState(false);

  const handleNext = () => {
    if (isEdit) {
      dispatch(setActiveStep(getStep1(jobDetailsById.work_mode)));
      dispatch(setActiveStep2(5));
      dispatch(setActiveStep3(8));
    } else {
      if (activeStep < getStep1(jobDetailsById.work_mode)) {
        dispatch(setActiveStep(activeStep + 1));
      } else if (
        activeStep === getStep1(jobDetailsById.work_mode) &&
        activeStep2 < 5
      ) {
        dispatch(setActiveStep2(activeStep2 + 1));
      } else if (activeStep2 === 5 && activeStep3 < 9) {
        dispatch(setActiveStep3(activeStep3 + 1));
      }
    }
  };

  const handleBack = () => {
    if (activeStep3 > 0) {
      dispatch(setActiveStep3(activeStep3 - 1));
    } else if (activeStep2 > 0) {
      dispatch(setActiveStep2(activeStep2 - 1));
    } else if (activeStep > 0) {
      dispatch(setActiveStep(activeStep - 1));
    }
  };

  const handleCreateOrUpdate = async (isUpdate = false) => {
    setLoading(true);
    try {
      const modifyOpportunity = {
        ...postOpportunity,
      } as PostOpportunityProps;
      const response = isUpdate
        ? await JobsService.updateOpportunity({
            ...modifyOpportunity,
            nid: jobDetailsById?.nid,
          })
        : await JobsService.createOpportunity(modifyOpportunity);

      if (response) {
        const getResponse = await JobsService.getJobDetailsById({
          nid: response.data.nid,
        });
        dispatch(setJobDetailsById(getResponse.results[0]));
      }
      handleNext();
      dispatch(setIsPosted(true));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = () => {
    handleCreateOrUpdate();
  };

  const handleUpdate = () => {
    handleCreateOrUpdate(true);
  };

  const progress = (activeStep / getStep1(jobDetailsById.work_mode)) * 100;
  const progress2 = (activeStep2 / 5) * 100;
  const progress3 = (activeStep3 / 9) * 100;

  const disabled = () => {
    const conditions = [
      !postOpportunity.job_title.length && activeStep2 === 2,
      !postOpportunity.job_decription.length && activeStep2 === 3,
      !postOpportunity.job_what_you_ll_do.length && activeStep2 === 3,
      !postOpportunity.job_type_id.length && activeStep === 3,
      !postOpportunity.job_category_id.length && activeStep === 2,
      !postOpportunity.job_work_mode_id.length && activeStep === 6,
      selectedConfirm.length !== 4 && activeStep3 === 8,
      !postOpportunity.job_project_id.length && activeStep3 === 9,
    ];
    return conditions.some(Boolean);
  };

  return (
    <>
      <StepComponent
        steps={[progress, progress2, progress3]}
        handleBack={handleBack}
        handleNext={handleNext}
        handleSubmit={handleSubmit}
        isActiveStep={activeStep === 1}
        isShowSubmitBtn={
          activeStep === getStep1(jobDetailsById.work_mode) &&
          activeStep2 === 5 &&
          activeStep3 === 9
        }
        isDisabled={
          isDisable ||
          disabled() ||
          (activeStep === getStep1(jobDetailsById.work_mode) &&
            activeStep2 === 5 &&
            activeStep3 > 9)
        }
      />
    </>
  );
};

export default Stepper;

export const getStepCondition = (type) => {
  return 6;
};
